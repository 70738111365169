export const hscLinkArr = [
    {
        to: "/science-course",
        child: {
            en: "Science",
            bn: "বিজ্ঞান",
        },
    },
    {
        to: "/commerce-course",
        child: {
            en: "Commerce",
            bn: "ব্যবসায় শিক্ষা",
        },
    },
    {
        to: "/arts-course",
        child: {
            en: "Arts",
            bn: "মানবিক",
        },
    },
    {
        to: "/hsc-teachers",
        child: {
            en: "Teachers",
            bn: "শিক্ষকমন্ডলি",
        },
    },
];

export const degreeLinkArr = [
    {
        to: "/ba-course",
        child: {
            en: "BA",
            bn: "বি.এ",
        },
    },
    {
        to: "/bss-course",
        child: {
            en: "BSS",
            bn: "বি.এস.এস",
        },
    },
    {
        to: "/bsc-course",
        child: {
            en: "BSc",
            bn: "বি.এস.সি",
        },
    },
    {
        to: "/bbs-course",
        child: {
            en: "BBS",
            bn: "বি.বি.এস",
        },
    },
    {
        to: "/degree-teachers",
        child: {
            en: "Teachers",
            bn: "শিক্ষকমন্ডলি",
        },
    },
];
