import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { NavLink } from "react-router-dom";

const Logo = ({ lang, closeMenu }) => {
    return (
        <NavLink reloadDocument onClick={closeMenu} to="/" className="logo">
            <LazyLoadImage
                src="https://res.cloudinary.com/dbqiwfbsy/image/upload/v1677247823/ParbatipurGovtCollege/navbar/govt__logo_cx9etp.png"
                alt=""
            />
            <p>
                {lang === "EN"
                    ? "PARBATIPUR GOVERNMENT COLLEGE"
                    : "পার্বতীপুর সরকারি কলেজ"}
            </p>
        </NavLink>
    );
};

export default Logo;
