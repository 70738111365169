import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../../css/dashboard/common/selectDate/selectDate.scss";

const SelectDate = ({
    handleDate,
    show,
    setShow,
    user,
    setUser,
    cls,
    title = "Date: ",
    location,
}) => {
    const handleDay = (value, event) => {
        setShow(false);

        if (location === "form") {
            setUser((prevUser) => ({
                ...prevUser,
                date_of_birth: value.toDateString(),
            }));
        } else if (location === "join") {
            setUser((prevUser) => ({
                ...prevUser,
                join_date: value.toDateString(),
            }));
        } else {
            setUser((prevUser) => ({
                ...prevUser,
                date: value.toDateString(),
            }));
        }
    };

    return (
        <div className={`select__date ${cls}`}>
            <div className="label">{title} </div>
            <div onClick={handleDate} className="date__input">
                {user.date || user}
            </div>
            {show && <Calendar onClickDay={handleDay} value={user.date} />}
        </div>
    );
};

export default SelectDate;
