import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../../pages/NotFound";
const LazyTeacherHome = lazy(() =>
    import("../../pages/dashboard/teacher/Home")
);
const LazyTeacherAttendance = lazy(() =>
    import("../../pages/dashboard/teacher/Attendance")
);
const LazyMyAttendance = lazy(() =>
    import("../../pages/dashboard/teacher/MyAttendance")
);
const LazyTeacherResult = lazy(() =>
    import("../../pages/dashboard/teacher/Result")
);

const TeacherRoute = () => {
    return (
        <Routes>
            <Route path="/teacher-dashboard" element={<LazyTeacherHome />} />

            <Route
                path="/teacher-dashboard/student-attendance"
                element={<LazyTeacherAttendance />}
            />
            <Route
                path="/teacher-dashboard/my-attendance"
                element={<LazyMyAttendance />}
            />
            <Route
                path="/teacher-dashboard/results"
                element={<LazyTeacherResult />}
            />
            <Route path="/teacher-dashboard/*" element={<NotFound />} />
        </Routes>
    );
};

export default TeacherRoute;
