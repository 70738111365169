import React from "react";
import { NavLink } from "react-router-dom";

const Marquee = () => {
    return (
        <div className="marquee__wrapper">
            <div className="marquee">
                <NavLink to="/" style={{ pointerEvents: "none" }}>
                    Welcome to Parbatipur Government College
                </NavLink>
            </div>
            {/* {noticeArray.slice(0, 2).map((note, index) => (
        <div key={index} className="marquee">
            <NavLink to={note.link}>{note.desc}</NavLink>
        </div>
    ))} */}
        </div>
    );
};

export default Marquee;
