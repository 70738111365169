import React from "react";
import BottomFooterContent from "./footerContent/BottomFooterContent";
import MiddleFooterContent from "./footerContent/MiddleFooterContent";
import TopFooterContent from "./footerContent/TopFooterContent";

const FooterContent = ({ lang }) => {
    return (
        <footer>
            <div className="content">
                <TopFooterContent lang={lang} />
                <MiddleFooterContent lang={lang} />
                <BottomFooterContent lang={lang} />
            </div>
        </footer>
    );
};

export default FooterContent;
