import React from "react";
import Dropdown from "./dropDown/Dropdown";

const AcademicLink = ({ closeMenu, menuClick, lang }) => {
    return (
        <div style={{ cursor: "pointer" }}>
            <span className="mb__none">
                {lang === "EN" ? "Academic" : "একাডেমিক"}
            </span>

            <Dropdown lang={lang} menuClick={menuClick} closeMenu={closeMenu} />
        </div>
    );
};

export default AcademicLink;
