import React, { lazy, useState } from "react";
import { Route, Routes } from "react-router-dom";
import AddNotice from "../../components/dashboard/admin/element/content/noticeEvent/element/AddNotice";
import AddEvent from "../../components/dashboard/admin/element/content/noticeEvent/element/AddEvent";
import NotFound from "../../pages/NotFound";

const LazyAdminHome = lazy(() => import("../../pages/dashboard/admin/Home"));

const LazyAdminDegree = lazy(() =>
    import("../../pages/dashboard/admin/Degree")
);
const LazyAdminDepartment = lazy(() =>
    import("../../pages/dashboard/admin/Departments")
);
const LazyAdminCourse = lazy(() =>
    import("../../pages/dashboard/admin/Course")
);
const LazyAdminLabs = lazy(() => import("../../pages/dashboard/admin/Labs"));
const LazyAdminAdmission = lazy(() =>
    import("../../pages/dashboard/admin/Admission")
);
const LazyAdminStudents = lazy(() =>
    import("../../pages/dashboard/admin/Students")
);

const LazyAdminPromoteStudents = lazy(() =>
    import("../../pages/dashboard/admin/PromoteStudent")
);
const LazyAdminTeachers = lazy(() =>
    import("../../pages/dashboard/admin/Teachers")
);
const LazyAdminStudentAttendance = lazy(() =>
    import("../../pages/dashboard/admin/StudentAttendance")
);
const LazyAdminTeacherAttendance = lazy(() =>
    import("../../pages/dashboard/admin/TeacherAttendace")
);
const LazyAdminResult = lazy(() =>
    import("../../pages/dashboard/admin/Result")
);
const LazyAdminResultPublish = lazy(() =>
    import("../../pages/dashboard/admin/ResultPublish")
);
const LazyAdminNoticeEvent = lazy(() =>
    import("../../pages/dashboard/admin/AllNotice")
);
const LazyAdminStudentFee = lazy(() =>
    import("../../pages/dashboard/admin/StudentFee")
);

const LazyHscStudentProfile = lazy(() =>
    import(
        "../../components/dashboard/admin/element/content/common/element/wrapperContent/Profile"
    )
);

const AdminRoute = () => {
    const [showForm, setShowForm] = useState(false);

    const handleAdd = () => {
        setShowForm(true);
    };
    const handleClose = () => {
        setShowForm(false);
    };
    return (
        <Routes>
            <Route path="/admin-dashboard" element={<LazyAdminHome />} />
            <Route
                path="/admin-dashboard/degrees"
                element={<LazyAdminDegree />}
            />
            <Route
                path="/admin-dashboard/departments"
                element={<LazyAdminDepartment />}
            />
            <Route
                path="/admin-dashboard/courses"
                element={<LazyAdminCourse />}
            />
            <Route path="/admin-dashboard/labs" element={<LazyAdminLabs />} />

            <Route
                path="/admin-dashboard/admission"
                element={<LazyAdminAdmission />}
            />
            <Route
                path="/admin-dashboard/students"
                element={<LazyAdminStudents />}
            />
            <Route
                path="/admin-dashboard/promote-students"
                element={<LazyAdminPromoteStudents />}
            />
            <Route
                path="/admin-dashboard/teachers"
                element={<LazyAdminTeachers />}
            />
            <Route
                path="/admin-dashboard/students-attendance"
                element={<LazyAdminStudentAttendance />}
            />
            <Route
                path="/admin-dashboard/teachers-attendance"
                element={<LazyAdminTeacherAttendance />}
            />
            <Route
                path="/admin-dashboard/exam-results"
                element={<LazyAdminResult />}
            />
            <Route
                path="/admin-dashboard/publish-results"
                element={<LazyAdminResultPublish />}
            />
            <Route
                path="/admin-dashboard/notice"
                element={
                    <LazyAdminNoticeEvent
                        title="Add New Notice"
                        handleAdd={handleAdd}
                        type="notice"
                        showForm={showForm}
                        child={<AddNotice handleClose={handleClose} />}
                    />
                }
            />
            <Route
                path="/admin-dashboard/events"
                element={
                    <LazyAdminNoticeEvent
                        title="Add New Event"
                        handleAdd={handleAdd}
                        type="event"
                        showForm={showForm}
                        child={<AddEvent handleClose={handleClose} />}
                    />
                }
            />
            <Route
                path="/admin-dashboard/student-fees"
                element={<LazyAdminStudentFee />}
            />

            <Route
                path="/admin-dashboard/hsc-student-profile/:degree/:id"
                element={<LazyHscStudentProfile />}
            />

            <Route path="/admin-dashboard/*" element={<NotFound />} />
        </Routes>
    );
};

export default AdminRoute;
