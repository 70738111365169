import React from "react";
import DropdownPrototype from "./dropdownPrototype/DropdownPrototype";
import { hscLinkArr, degreeLinkArr } from "../../../../../lib/navbar/navbar";

const Dropdown = ({ lang, closeMenu, menuClick }) => {
    return (
        <div className="dropdown">
            <DropdownPrototype
                to="/hsc"
                child={lang === "EN" ? "HSC" : "এইচ.এস.সি"}
                lang={lang}
                closeMenu={closeMenu}
                menuClick={menuClick}
                arr={hscLinkArr}
            />

            <DropdownPrototype
                to="/degree"
                child={lang === "EN" ? "Degree" : "ডিগ্রী"}
                lang={lang}
                closeMenu={closeMenu}
                menuClick={menuClick}
                arr={degreeLinkArr}
            />
        </div>
    );
};

export default Dropdown;
