import axios from "axios";
import Cookies from "js-cookie";

// const baseUrl = "https://7f9d-103-65-226-22.ngrok-free.app/api/v1";
// const baseUrl = "https://c8b8-103-65-226-30.ngrok-free.app/api/v1";
const baseUrl = process.env.REACT_APP_BASE_URL;
// const baseUrl = process.env.REACT_APP_DEV_URL;
// const baseUrl = process.env.REACT_APP_BASE_URL_2;

// SignUp Student
export const signUp = async (
    api,
    userDetails,
    setError,
    setProcessingMsg,
    setSuccessMsg,
    setAuth
) => {
    setProcessingMsg("SignUp Processing...");
    try {
        const response = await axios.post(
            `${baseUrl}/${api}/signUp`,
            {
                email: userDetails.email,
                password: userDetails.password,
                token: userDetails.token,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data + " You can Login Now.");
        setTimeout(() => {
            setSuccessMsg("");
            window.location.href = "/sign-in";
        }, 1500);
        // const { accessToken, email } = response.data;

        // Cookies.set(
        //     "user",
        //     JSON.stringify({
        //         accessToken,
        //         email,
        //         api,
        //         auth: true,
        //     })
        // );

        // setTimeout(() => {
        //     setAuth(true);

        //     switch (api) {
        //         case "admin":
        //             window.location.href = "/admin-dashboard";
        //             break;
        //         case "teacher":
        //             window.location.href = "/teacher-dashboard";
        //             break;
        //         case "hsc/student":
        //             window.location.href = "/student-dashboard";
        //             break;
        //         case "bsc/student":
        //             window.location.href = "/student-dashboard";
        //             break;
        //         default:
        //     }
        // }, 1000);
    } catch (error) {
        setProcessingMsg("");
        setError("Email or Token not correct! Please try again.");
        setTimeout(() => setError(""), 8000);
    }
};

// SignIn Student
export const signIn = async (
    api,
    userDetails,
    setError,
    setSuccessMsg,
    setProcessingMsg,
    setAuth
) => {
    setProcessingMsg("Login processing...!");
    try {
        const response = await axios.post(
            `${baseUrl}/${api}/signIn`,
            {
                email: userDetails.email,
                password: userDetails.password,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg("Login Successful!");
        const { accessToken, email } = response.data;

        Cookies.set(
            "user",
            JSON.stringify({
                accessToken,
                email,
                api,
            }),
            {
                expires: 1,
                path: "/",
            }
        );

        setTimeout(() => {
            setAuth(true);
            switch (api) {
                case "admin":
                    window.location.href = "/admin-dashboard";
                    break;
                case "teacher":
                    window.location.href = "/teacher-dashboard";
                    break;
                case "hsc/student":
                    window.location.href = "/student-dashboard";
                    break;
                case "bsc/student":
                    window.location.href = "/student-dashboard";
                    break;
                default:
            }
        }, 1000);
    } catch (error) {
        setProcessingMsg("");
        setError("Email or Password not correct! Please try again.");
        setTimeout(() => setError(""), 8000);
    }
};

// Get User
export const getUser = async (setUser, setLoading, api, email, accessToken) => {
    try {
        const response = await axios.get(`${baseUrl}/${api}/getByEmail`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                email,
            },
        });

        const user = await response.data;

        setUser((prevState) => ({
            ...prevState,
            api,
            email: user.email,
            name: user.name || user.studentNameEnglish || user.teacherName,
            accessToken,
            details: user,
        }));
    } catch (error) {
        setInterval(() => setLoading(false), 3000);
    }
};

// Get unAuthenticated Students
export const getStudentByEmail = async (api, email, accessToken, setCourse) => {
    try {
        const response = await axios.get(
            `${baseUrl}/${api}/student/getByEmail`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    email,
                },
            }
        );

        setCourse((prevState) => ({
            ...prevState,
            student_id: response.data.studentId,
        }));
    } catch (error) {}
};

// Get unAuthenticated Teacher
export const getTeacherByEmail = async (api, email, accessToken, setCourse) => {
    try {
        const response = await axios.get(`${baseUrl}/teacher/getByEmail`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                email,
            },
        });

        setCourse((prevState) => ({
            ...prevState,
            student_id: response.data.teacherId,
        }));
    } catch (error) {}
};

// Get Degrees
export const getAllDegree = async (setLists, accessToken, setLoading) => {
    try {
        const response = await axios.get(`${baseUrl}/degree/getAll`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        setLists((prevState) => ({
            ...prevState,
            degree: response.data,
        }));

        setLoading && setLoading(false);
    } catch (error) {
        setInterval(() => setLoading(false), 3000);
    }
};

// Get Degrees
export const getAllDegreeV2 = async (setLists, accessToken, setLoading) => {
    try {
        const response = await axios.get(`${baseUrl}/degree/getAll`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        setLists((prevState) => ({
            ...prevState,
            array: {
                ...prevState.array,
                degree: response.data,
            },
        }));

        setLoading && setLoading(false);
    } catch (error) {
        setInterval(() => setLoading(false), 3000);
    }
};

// Get Departments
export const getAllDepartment = async (
    degree,
    setLists,
    accessToken,
    setLoading
) => {
    try {
        const newDepartmentState = {};
        for (const el of degree) {
            const response = await axios.get(
                `${baseUrl}/department/getByDegree`,
                {
                    headers: {
                        "ngrok-skip-browser-warning": 123,
                        "Content-type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    params: {
                        degreeId: el.degreeId,
                    },
                }
            );

            newDepartmentState[el.degreeName.split(" ")[0].toLowerCase()] =
                response.data;
        }

        setLists((prevState) => ({
            ...prevState,
            department: { ...prevState.department, ...newDepartmentState },
        }));

        setLoading && setLoading(false);
    } catch (error) {
        setLoading && setInterval(() => setLoading(false), 3000);
    }
};

// Get single Department  ************
export const getDepartment = async (
    degreeId,
    setLists,
    accessToken,
    setLoading
) => {
    setLoading && setLoading(true);
    try {
        const response = await axios.get(`${baseUrl}/department/getByDegree`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                degreeId,
            },
        });

        let newArray = [];

        response.data.forEach((res) =>
            newArray.push({
                name: res.departmentName,
                id: res.departmentId,
            })
        );

        setLists((prevState) => ({
            ...prevState,
            array: {
                ...prevState.array,
                department: newArray,
                // degree: false,
            },
        }));

        console.log(response.data);

        setLoading && setLoading(false);
    } catch (error) {
        setLoading && setInterval(() => setLoading(false), 3000);
    }
};

// Get single Department V2 ************
export const getDepartmentV2 = async (
    degreeId,
    setLists,
    accessToken,
    setLoading
) => {
    setLoading && setLoading(true);

    try {
        const response = await axios.get(`${baseUrl}/department/getByDegree`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                degreeId,
            },
        });

        setLists((prevState) => ({
            ...prevState,
            array: {
                ...prevState.array,
                department: [...prevState.array.department, ...response.data],
            },
        }));
    } catch (error) {
        setLists((prevState) => ({
            ...prevState,
            array: {
                ...prevState.array,
                department: [],
            },
        }));
        setLoading && setInterval(() => setLoading(false), 3000);
    } finally {
        setLoading && setLoading(false);
    }
};

// Get Courses
export const getCourse = async (
    degreeId,
    departmentId,
    yearName,
    setLists,
    accessToken,
    setSendingCourse,
    setLoading
) => {
    try {
        const response = await axios.get(
            `${baseUrl}/course/getByDegreeDepartmentStudentClass`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId: degreeId,
                    departmentId: departmentId,
                    studentClass: yearName,
                },
            }
        );

        const datas = await response.data;

        console.log(response.data);

        setLists((prevState) => ({
            ...prevState,
            courses: {
                [response.data[0].degreeName.split(" ")[0].toLowerCase()]:
                    datas,
            },
        }));

        setSendingCourse &&
            setSendingCourse((prevState) => ({
                ...prevState,
                courses: datas,
            }));

        setLoading && setLoading(false);
    } catch (error) {
        setLists((prevState) => ({
            ...prevState,
            courses: {},
        }));
        // console.log("Courses Not Found");
    }
};

// Get Courses
export const getCourseByDegreeDepartmentClass = async (
    degreeId,
    departmentId,
    yearName,
    setLists,
    accessToken,
    setLoading
) => {
    try {
        const response = await axios.get(
            `${baseUrl}/course/getByDegreeDepartmentStudentClass`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId: degreeId,
                    departmentId: departmentId,
                    studentClass: yearName,
                },
            }
        );

        setLists(response.data);

        setLoading && setLoading(false);
    } catch (error) {
        setLists([]);
        // console.log("Courses Not Found");
        setLoading && setLoading(false);
    }
};

// Get Course by degree and depertment
export const getCourseByDegreeDept = async (
    degreeId,
    departmentId,
    accessToken,
    setData,
    setLoading
) => {
    try {
        const response = await axios.get(
            `${baseUrl}/course/getByDegreeDepartment`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId: degreeId,
                    departmentId: departmentId,
                },
            }
        );

        const arr = [];

        response.data.forEach((el) =>
            arr.push({
                id: el.courseId,
                name: el.courseName,
            })
        );

        setData((prevState) => ({
            ...prevState,
            array: { ...prevState.array, course: arr },
        }));
        setLoading(false);
    } catch (error) {
        setLoading && setInterval(() => setLoading(false), 3000);
    }
};

export const getAllCourse = async (
    degreeId,
    departmentId,
    setSendingCourse,
    accessToken,
    setLoading
) => {
    try {
        const response = await axios.get(
            `${baseUrl}/course/getByDegreeDepartment`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                },
            }
        );

        const datas = await response.data;

        setSendingCourse((prevState) => ({
            ...prevState,
            courses: datas,
        }));
        setLoading && setLoading(false);
    } catch (error) {}
};

// Get Labs
export const getLab = async (
    degreeId,
    departmentId,
    yearName,
    setLists,
    accessToken
) => {
    try {
        const response = await axios.get(
            `${baseUrl}/lab/getLabByDegreeDepartmentStudentClass`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId: degreeId,
                    departmentId: departmentId,
                    studentClass: yearName,
                },
            }
        );

        const datas = await response.data;

        setLists((prevState) => ({
            ...prevState,
            labs: {
                [response.data[0].degree.degreeName
                    .split(" ")[0]
                    .toLowerCase()]: datas,
            },
        }));
    } catch (error) {
        setLists((prevState) => ({
            ...prevState,
            labs: {},
        }));
    }
};

// Get Labs
export const getAllLabsDegreeDeptYear = async (
    degreeId,
    departmentId,
    yearName,
    setLists,
    accessToken,
    setLoading
) => {
    setLoading(true);
    try {
        const response = await axios.get(
            `${baseUrl}/lab/getLabByDegreeDepartmentStudentClass`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId: degreeId,
                    departmentId: departmentId,
                    studentClass: yearName,
                },
            }
        );

        setLists(response.data);
        setLoading(false);
    } catch (error) {
        setLists([]);
        setLoading(false);
    }
};

// Add Hsc Student
export const addHscStudent = async (
    user,
    accessToken,
    setLoading,
    setOption,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    // setLoading(true);
    setProcessingMsg("Student Addition Processing...!");
    try {
        const response = await axios.post(
            `${baseUrl}/hsc/student/save`,
            {
                studentNameEnglish: user.student_name_en,
                studentNameBangla: user.student_name_bn,
                studentFatherName: user.student_father_name_en,
                studentFatherNameBangla: user.student_father_name_bn,
                studentMotherName: user.student_mother_name_en,
                studentMotherNameBangla: user.student_mother_name_bn,
                studentBirthDate: user.date_of_birth,
                studentBirthCertificateNumber: user.birth_certificate_no,
                studentNidNumber: user.nid_no,
                gender: user.gender,
                religion: user.religion,
                ethnicGroup: user.ethnic_group,
                nationality: user.nationality,
                division: user.division,
                district: user.district,
                upazilaOrCityCorporation: user.upazila,
                postOffice: user.post_office,
                postCode: user.post_code,
                address: user.address,
                phone: user.mobile,
                email: user.email,
                fatherPhone: user.father_mobile,
                motherPhone: user.mother_mobile,
                localGuardianName: user.local_guardian,
                localGuardianPhone: user.local_guardian_mobile,
                localGuardianAddress: user.local_guardian_address,
                sscDegree:
                    user.secondary_degree === "ssc"
                        ? user.secondary_degree
                        : user.secondary_degree_equivalent,
                sscGroup: user.s_group,
                sscYear: user.s_year,
                sscBoard: user.sscBoard,
                sscSchool: user.secondary_degree_school,
                sscRoll: user.s_d_roll_no,
                sscRegistrationNumber: user.s_d_registration_no,
                sscResult: parseFloat(user.s_d_result),
                degreeId: user.degreeId,
                departmentId: user.departmentId,
                studentClass: user.studentClass,
                admissionDate: user.admission_date,
                session: user.session,
                passingYear: user.passing_year,
                collegeRoll: user.college_roll,
                section: "N/A",
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        // setLoading(false);
        setProcessingMsg("");
        setSuccessMsg(response.data + ". Wait a minute...");
        // setTimeout(() => setOption("add course"), 2000);
        setTimeout(() => setOption("add photo"), 2000);
    } catch (error) {
        // setInterval(() => setLoading(false), 3000);
        setProcessingMsg("");
        setError("Can't add for some Reason");
        // window.location.reload();
    }
};

// Add Hsc Student
export const updateHscStudent = async (
    user,
    accessToken,
    setLoading,
    setOption,
    studentId,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Student Update Processing...!");
    try {
        const response = await axios.put(
            `${baseUrl}/hsc/student/update`,
            {
                studentNameEnglish: user.student_name_en,
                studentNameBangla: user.student_name_bn,
                studentFatherName: user.student_father_name_en,
                studentFatherNameBangla: user.student_father_name_bn,
                studentMotherName: user.student_mother_name_en,
                studentMotherNameBangla: user.student_mother_name_bn,
                studentBirthDate: user.date_of_birth,
                studentBirthCertificateNumber: user.birth_certificate_no,
                studentNidNumber: user.nid_no,
                gender: user.gender,
                religion: user.religion,
                ethnicGroup: user.ethnic_group,
                nationality: user.nationality,
                division: user.division,
                district: user.district,
                upazilaOrCityCorporation: user.upazila,
                postOffice: user.post_office,
                postCode: user.post_code,
                address: user.address,
                phone: user.mobile,
                email: user.email,
                fatherPhone: user.father_mobile,
                motherPhone: user.mother_mobile,
                localGuardianName: user.local_guardian,
                localGuardianPhone: user.local_guardian_mobile,
                localGuardianAddress: user.local_guardian_address,
                sscDegree:
                    user.secondary_degree === "ssc"
                        ? user.secondary_degree
                        : user.secondary_degree_equivalent,
                sscGroup: user.s_group,
                sscYear: user.s_year,
                sscBoard: user.sscBoard,
                sscSchool: user.secondary_degree_school,
                sscRoll: user.s_d_roll_no,
                sscRegistrationNumber: user.s_d_registration_no,
                sscResult: parseFloat(user.s_d_result),
                degreeId: user.degreeId,
                departmentId: user.departmentId,
                studentClass: user.studentClass,
                admissionDate: user.admission_date,
                session: user.session,
                passingYear: user.passing_year,
                collegeRoll: user.college_roll,
                section: "N/A",
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    studentId,
                },
            }
        );

        // setLoading(false);
        setProcessingMsg("");
        setSuccessMsg(response.data + ". Wait a minute...");
        setTimeout(() => setOption("update photo"), 2000);
    } catch (error) {
        setProcessingMsg("");
        setError("Can't Update for some Reason");

        setInterval(() => setLoading(false), 3000);
        // window.location.reload();
    }
};

// Add Degree Student
export const addDegreeStudent = async (
    user,
    accessToken,
    setLoading,
    setOption,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    // setLoading(true);
    setProcessingMsg("Student Addition Processing...!");
    try {
        const response = await axios.post(
            `${baseUrl}/bsc/student/save`,
            {
                studentNameEnglish: user.student_name_en,
                studentNameBangla: user.student_name_bn,
                studentFatherName: user.student_father_name_en,
                studentFatherNameBangla: user.student_father_name_bn,
                studentMotherName: user.student_mother_name_en,
                studentMotherNameBangla: user.student_mother_name_bn,
                studentBirthDate: user.date_of_birth,
                studentBirthCertificateNumber: user.birth_certificate_no,
                studentNidNumber: user.nid_no,
                gender: user.gender,
                religion: user.religion,
                ethnicGroup: user.ethnic_group,
                nationality: user.nationality,
                division: user.division,
                district: user.district,
                upazilaOrCityCorporation: user.upazila,
                postOffice: user.post_office,
                postCode: user.post_code,
                address: user.address,
                phone: user.mobile,
                email: user.email,
                fatherPhone: user.father_mobile,
                motherPhone: user.mother_mobile,
                localGuardianName: user.local_guardian,
                localGuardianPhone: user.local_guardian_mobile,
                localGuardianAddress: user.local_guardian_address,
                sscDegree:
                    user.secondary_degree === "ssc"
                        ? user.secondary_degree
                        : user.secondary_degree_equivalent,
                sscGroup: user.s_group,
                sscYear: user.s_year,
                sscBoard: user.s_board,
                sscSchool: user.secondary_degree_school,
                sscRoll: user.s_d_roll_no,
                sscRegistrationNumber: user.s_d_registration_no,
                sscResult: user.s_d_result,
                hscDegree:
                    user.higher_secondary_degree === "hsc"
                        ? user.higher_secondary_degree
                        : user.higher_secondary_degree_equivalent,
                hscGroup: user.h_s_group,
                hscYear: user.h_s_year,
                hscBoard: user.h_s_board,
                hscCollege: user.higher_secondary_degree_school,
                hscRoll: user.h_s_d_roll_no,
                hscRegistrationNumber: user.h_s_d_registration_no,
                hscResult: user.h_s_d_result,
                degreeId: user.degreeId,
                departmentId: user.departmentId,
                studentClass: user.studentClass,
                admissionDate: user.admission_date,
                session: user.session,
                passingYear: user.passing_year,
                collegeRoll: user.college_roll,
                section: "N/A",
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data + ". Wait a minute...");
        // setLoading(false);
        setTimeout(() => setOption("add photo"), 2000);
    } catch (error) {
        // setTimeout(() => setLoading(false), 3000);
        setProcessingMsg("");
        setError("Can't add for some Reason");

        setTimeout(() => setError(""), 3000);
        // window.location.reload();
    }
};

// Update Student By Id
export const updateDegreeStudent = async (
    user,
    accessToken,
    setLoading,
    setOption,
    studentId,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Student Update Processing...!");

    try {
        const response = await axios.put(
            `${baseUrl}/bsc/student/update`,
            {
                studentNameEnglish: user.student_name_en,
                studentNameBangla: user.student_name_bn,
                studentFatherName: user.student_father_name_en,
                studentFatherNameBangla: user.student_father_name_bn,
                studentMotherName: user.student_mother_name_en,
                studentMotherNameBangla: user.student_mother_name_bn,
                studentBirthDate: user.date_of_birth,
                studentBirthCertificateNumber: user.birth_certificate_no,
                studentNidNumber: user.nid_no,
                gender: user.gender,
                religion: user.religion,
                ethnicGroup: user.ethnic_group,
                nationality: user.nationality,
                division: user.division,
                district: user.district,
                upazilaOrCityCorporation: user.upazila,
                postOffice: user.post_office,
                postCode: user.post_code,
                address: user.address,
                phone: user.mobile,
                email: user.email,
                fatherPhone: user.father_mobile,
                motherPhone: user.mother_mobile,
                localGuardianName: user.local_guardian,
                localGuardianPhone: user.local_guardian_mobile,
                localGuardianAddress: user.local_guardian_address,
                sscDegree:
                    user.secondary_degree === "ssc"
                        ? user.secondary_degree
                        : user.secondary_degree_equivalent,
                sscGroup: user.s_group,
                sscYear: user.s_year,
                sscBoard: user.s_board,
                sscSchool: user.secondary_degree_school,
                sscRoll: user.s_d_roll_no,
                sscRegistrationNumber: user.s_d_registration_no,
                sscResult: user.s_d_result,
                hscDegree:
                    user.higher_secondary_degree === "hsc"
                        ? user.higher_secondary_degree
                        : user.higher_secondary_degree_equivalent,
                hscGroup: user.h_s_group,
                hscYear: user.h_s_year,
                hscBoard: user.h_s_board,
                hscCollege: user.higher_secondary_degree_school,
                hscRoll: user.h_s_d_roll_no,
                hscRegistrationNumber: user.h_s_d_registration_no,
                hscResult: user.h_s_d_result,
                degreeId: user.degreeId,
                departmentId: user.departmentId,
                studentClass: user.studentClass,
                admissionDate: user.admission_date,
                session: user.session,
                passingYear: user.passing_year,
                collegeRoll: user.college_roll,
                section: "N/A",
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    studentId,
                },
            }
        );

        // setLoading(false);
        setProcessingMsg("");
        setSuccessMsg(response.data + ". Wait a minute...");
        setTimeout(() => setOption("update photo"), 2000);
    } catch (error) {
        setProcessingMsg("");
        setError("Can't Update for some Reason");

        setTimeout(() => setError(""), 3000);

        // setInterval(() => setLoading(false), 3000);
        // window.location.reload();
    }
};

// Delete Student By Id
export const deleteStudentById = async (
    api,
    id,
    setLoading,
    accessToken,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.delete(
            `${baseUrl}/${api}/student/delete`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    studentId: id,
                },
            }
        );

        // setLoading(false);

        // alert("Deleted Successfully!");
        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        // setError("Can't Delete for some Reason");
        setError(error.message);

        setTimeout(() => setError(""), 5000);
        // alert(error.message);
        // setInterval(() => setLoading(false), 3000);
    }
};

// Get All Students
export const getAllStudents = async (
    api,
    accessToken,
    setStudent,
    degreeId,
    departmentId,
    studentClass,
    setLoading
) => {
    setLoading(true);
    try {
        const response = await axios.get(
            `${baseUrl}/${api}/student/studentsByDegreeDepartmentStudentClass`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    studentClass,
                },
            }
        );

        const data = response.data;

        setStudent(data);
        setLoading(false);
    } catch (error) {
        setStudent([]);
        setInterval(() => setLoading(false), 3000);
    }
};

// Add Student Courses For Individuals
export const addCoursesIndividuals = async (
    api,
    studentId,
    studentClass,
    array,
    accessToken,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Course Addition Processing...!");
    try {
        const response = await axios.post(
            `${baseUrl}/${api}/student/course/save`,
            array,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    studentId: studentId,
                    studentClass: studentClass,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError("Can't add for some Reason");

        setTimeout(() => setError(""), 3000);

        // window.location.reload();
    }
};

// Update Student Courses For Individuals
export const updateCoursesIndividuals = async (
    api,
    studentId,
    studentClass,
    array,
    accessToken,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Course Updating Processing...!");
    try {
        const response = await axios.put(
            `${baseUrl}/${api}/student/course/update`,
            array,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    studentId: studentId,
                    studentClass: studentClass,
                },
            }
        );
        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError("Can't Update for some Reason");

        setTimeout(() => setError(""), 3000);

        // window.location.reload();
    }
};

// Get Individual Courses
export const getCoursesForIndividuals = async (
    api,
    degreeId,
    departmentId,
    studentId,
    studentClass,
    accessToken,
    setCourse
) => {
    try {
        const response = await axios.get(
            `${baseUrl}/${api}/student/course/getByDegreeDepartmentStudentStudentClass`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    studentId,
                    studentClass,
                },
            }
        );

        let array = [];

        response.data.forEach((el) =>
            array.push({
                courseCode: el.course.courseCode,
                courseName: el.course.courseName,
                studentClass: el.course.studentClass,
            })
        );

        setCourse(array);
    } catch (error) {}
};

// Get Individual Labs
export const getLabsForIndividuals = async (
    api,
    degreeId,
    departmentId,
    studentId,
    accessToken,
    setCourse
) => {
    try {
        const response = await axios.get(
            `${baseUrl}/${api}/student/lab/getByDegreeDepartmentStudent`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    studentId,
                },
            }
        );

        let array = [];
        response.data.forEach((el) =>
            array.push({
                courseCode: el.lab.labCode,
                courseName: el.lab.labName,
                studentClass: el.lab.studentClass,
            })
        );

        setCourse(array);
    } catch (error) {
        // console.log("Labs Not Found");
    }
};

// Add Teacher
export const addTeacher = async (
    user,
    accessToken,
    setLoading,
    setError,
    setSuccessMsg,
    setProcessingMsg,
    setOption
) => {
    setProcessingMsg("Teacher Addition Processing...");
    try {
        const response = await axios.post(
            `${baseUrl}/teacher/save`,
            {
                teacherName: user.teacher_name_en,
                teacherNameBangla: user.teacher_name_bn,
                teacherFatherName: user.teacher_father_name_en,
                teacherFatherNameBangla: user.teacher_father_name_bn,
                teacherMotherName: user.teacher_mother_name_en,
                teacherMotherNameBangla: user.teacher_mother_name_bn,
                brithDate: user.date_of_birth,
                birthCertificateNumber: user.birth_certificate_no,
                nidNumber: user.nid_no,
                gender: user.gender,
                religion: user.religion,
                maritalStatus: user.marital_status,
                ethnicGroup: user.ethnic_group,
                nationality: user.nationality,
                division: user.division,
                district: user.district,
                upazilaOrCityCorporation: user.upazila,
                address: user.address,
                postOffice: user.post_office,
                postCode: user.post_code,
                email: user.email,
                phone: user.mobile,
                emergencyContactName: user.emergency_contact,
                emergencyContactAddress: user.emergency_contact_address,
                emergencyContactPhone: user.emergency_contact_mobile,
                sscDegree: user.ssc_degree,
                sscGroup: user.s_group,
                sscResult: user.s_d_result,
                sscYear: user.s_year,
                sscBoard: user.s_board,
                sscRoll: user.s_d_roll_no,
                sscRegistrationNumber: user.s_d_registration_no,
                sscSchool: user.secondary_degree_school,
                hscDegree: user.hsc_degree,
                hscGroup: user.h_s_group,
                hscResult: user.h_s_d_result,
                hscYear: user.h_s_year,
                hscRoll: user.h_s_d_roll_no,
                hscBoard: user.h_s_board,
                hscRegistrationNumber: user.h_s_d_registration_no,
                hscCollege: user.higher_secondary_degree_school,
                bscDegree: user.bsc_degree,
                bscDegreeSubject: user.bsc_degree_subject,
                bscDegreeResult: user.bsc_degree_result,
                bscDegreeYear: user.bsc_degree_year,
                bscDegreeSession: user.bsc_degree_session,
                bscDegreeUniversity: user.bsc_degree_university,
                mscDegree: user.msc_degree,
                mscDegreeSubject: user.msc_degree_subject,
                mscDegreeResult: user.msc_degree_result,
                mscDegreeYear: user.msc_degree_year,
                mscDegreeSession: user.msc_degree_session,
                mscDegreeUniversity: user.msc_degree_university,
                phdDegree: user.phd_degree,
                phdDegreeSubject: user.phd_degree_subject,
                phdDegreeYear: user.phd_degree_year,
                phdDegreeUniversity: user.phd_degree_university,
                previousCollege: user.previous_college,
                degreeId: user.degreeId,
                departmentId: user.departmentId,
                courseId: user.courseId,
                joinDate: user.join_date,
                retirementDate: user.retirement_date,
                teacherCollegeId: user.college_id,
                designation: user.designation,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setOption("add teacher photo"), 2000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Update Teacher
export const updateTeacher = async (
    user,
    teacherId,
    accessToken,
    setLoading,
    setError,
    setSuccessMsg,
    setProcessingMsg,
    setOption
) => {
    setProcessingMsg("Teacher Update Processing...");
    try {
        const response = await axios.put(
            `${baseUrl}/teacher/update`,
            {
                teacherName: user.teacher_name_en,
                teacherNameBangla: user.teacher_name_bn,
                teacherFatherName: user.teacher_father_name_en,
                teacherFatherNameBangla: user.teacher_father_name_bn,
                teacherMotherName: user.teacher_mother_name_en,
                teacherMotherNameBangla: user.teacher_mother_name_bn,
                brithDate: user.date_of_birth,
                birthCertificateNumber: user.birth_certificate_no,
                nidNumber: user.nid_no,
                gender: user.gender,
                religion: user.religion,
                maritalStatus: user.marital_status,
                ethnicGroup: user.ethnic_group,
                nationality: user.nationality,
                division: user.division,
                district: user.district,
                upazilaOrCityCorporation: user.upazila,
                address: user.address,
                postOffice: user.post_office,
                postCode: user.post_code,
                email: user.email,
                phone: user.mobile,
                emergencyContactName: user.emergency_contact,
                emergencyContactAddress: user.emergency_contact_address,
                emergencyContactPhone: user.emergency_contact_mobile,
                sscDegree: user.ssc_degree,
                sscGroup: user.s_group,
                sscResult: user.s_d_result,
                sscYear: user.s_year,
                sscBoard: user.s_board,
                sscRoll: user.s_d_roll_no,
                sscRegistrationNumber: user.s_d_registration_no,
                sscSchool: user.secondary_degree_school,
                hscDegree: user.hsc_degree,
                hscGroup: user.h_s_group,
                hscResult: user.h_s_d_result,
                hscYear: user.h_s_year,
                hscRoll: user.h_s_d_roll_no,
                hscBoard: user.h_s_board,
                hscRegistrationNumber: user.h_s_d_registration_no,
                hscCollege: user.higher_secondary_degree_school,
                bscDegree: user.bsc_degree,
                bscDegreeSubject: user.bsc_degree_subject,
                bscDegreeResult: user.bsc_degree_result,
                bscDegreeYear: user.bsc_degree_year,
                bscDegreeSession: user.bsc_degree_session,
                bscDegreeUniversity: user.bsc_degree_university,
                mscDegree: user.msc_degree,
                mscDegreeSubject: user.msc_degree_subject,
                mscDegreeResult: user.msc_degree_result,
                mscDegreeYear: user.msc_degree_year,
                mscDegreeSession: user.msc_degree_session,
                mscDegreeUniversity: user.msc_degree_university,
                phdDegree: user.phd_degree,
                phdDegreeSubject: user.phd_degree_subject,
                phdDegreeYear: user.phd_degree_year,
                phdDegreeUniversity: user.phd_degree_university,
                previousCollege: user.previous_college,
                degreeId: user.degreeId,
                departmentId: user.departmentId,
                courseId: user.courseId,
                joinDate: user.join_date,
                retirementDate: user.retirement_date,
                teacherCollegeId: user.college_id,
                designation: user.designation,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    teacherId,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setOption("update teacher photo"), 2000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Get All teachers by degree and department
export const getAllTeachers = async (
    degreeId,
    departmentId,
    setData,
    accessToken
) => {
    try {
        const response = await axios.get(
            `${baseUrl}/teacher/getByDegreeDepartment`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                },
            }
        );

        setData(response.data);
    } catch (error) {
        setData([]);
        // console.log("Not to show");
    }
};

// Get All Teachers by Degree
export const getTeachersByDegree = async (
    degreeId,
    setDefaultOption,
    accessToken
) => {
    try {
        const response = await axios.get(`${baseUrl}/teacher/getByDegree`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                degreeId,
            },
        });

        setDefaultOption((prevState) => ({
            ...prevState,
            array: {
                teacher: response.data,
            },
        }));
    } catch (error) {
        // console.log("Not found!");
    }
};

// Delete Teacher By Id
export const deleteTeacherById = async (
    api,
    id,
    setLoading,
    accessToken,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.delete(`${baseUrl}/teacher/delete`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                teacherId: id,
            },
        });

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Promote Student
export const promoteStudent = async (
    api,
    currentClass,
    promotedClass,
    studentId,
    accessToken,
    setProcessingMsg,
    setSuccessMsg,
    setError
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.put(
            `${baseUrl}/${api}/student/course/promote`,
            {
                currentClass,
                promotedClass,
                promotedSection: "N/A",
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    [`${api}StudentId`]: studentId,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Change Active Status
export const changeActiveStatus = async (
    api,
    status,
    studentId,
    accessToken,
    setShowButton
) => {
    try {
        await axios.put(
            `${baseUrl}/${api}/student/setStatus`,
            { status },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    studentId,
                },
            }
        );

        alert("Successfully Updated");
        setShowButton(false);
    } catch (error) {
        alert(error.response.data.message);
    }
};

// Change Active Status
export const changeTeacherActiveStatus = async (
    api,
    status,
    teacherId,
    accessToken,
    setShowButton
) => {
    try {
        await axios.put(
            `${baseUrl}/teacher/setStatus`,
            { status },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    teacherId,
                },
            }
        );

        alert("Successfully Updated");
        setShowButton(false);
    } catch (error) {
        alert(error.response.data.message);
    }
};

// Get Courses by Degree Department
export const getAllCourseByDegreeDept = async (
    degreeId,
    departmentId,
    setData,
    setLoading,
    accessToken
) => {
    setLoading(true);
    try {
        const response = await axios.get(
            `${baseUrl}/course/getByDegreeDepartment`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                },
            }
        );
        setData(response.data);
        setLoading(false);
    } catch (error) {
        setData([]);
        setLoading(false);
    }
};

// Get Courses by Degree Department Year
export const getAllCourseByDegreeDeptYear = async (
    degreeId,
    departmentId,
    studentClass,
    setData,
    setLoading,
    accessToken
) => {
    setLoading(true);
    try {
        const response = await axios.get(
            `${baseUrl}/course/getByDegreeDepartmentStudentClass`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    studentClass,
                },
            }
        );
        setData(response.data);
        setLoading(false);
    } catch (error) {
        setData([]);
        setLoading(false);
    }
};

// Add Teacher Course
export const addTeacherCourse = async (
    array,
    teacherId,
    accessToken,
    setLoading,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Course Addition Processing...!");
    try {
        const response = await axios.post(
            `${baseUrl}/teacher/course/save`,
            array,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    teacherId,
                },
            }
        );
        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 5000);
    }
};

// Get Courses By Teacher
export const getCoursesByTeacher = async (
    degreeId,
    departmentId,
    teacherId,
    setCourses,
    accessToken,
    setLoading
) => {
    try {
        const response = await axios.get(
            `${baseUrl}/teacher/course/getByTeacher`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    teacherId,
                },
            }
        );

        let newArray = [];
        response.data.forEach((el) => newArray.push(el.course));

        setCourses(newArray);
        setLoading(false);
    } catch (error) {
        // alert(error.message);
        setLoading(false);
    }
};

// Update Teacher Courses
export const updateTeacherCourse = async (
    array,
    teacherId,
    degreeId,
    departmentId,
    accessToken,
    setLoading,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Course Update Processing...!");
    try {
        const response = await axios.put(
            `${baseUrl}/teacher/course/update`,
            array,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: { degreeId, departmentId, teacherId },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 5000);
    }
};

// Delete Teachers All Labs
export const deleteTeacherAllCourse = async (
    teacherId,
    accessToken,
    setLoading,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...!");
    try {
        const response = await axios.delete(
            `${baseUrl}/teacher/course/delete`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    teacherId,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 5000);
    }
};

// Add Teacher Lab
export const addTeacherLab = async (
    array,
    teacherId,
    accessToken,
    setLoading,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Lab Addition Processing...!");

    try {
        const response = await axios.post(
            `${baseUrl}/teacher/lab/save`,
            array,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    teacherId,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 5000);
    }
};

// Get Teacher Labs
export const getAllTeacherLabs = async (
    degreeId,
    departmentId,
    accessToken,
    setCourses,
    setLoading
) => {
    try {
        const response = await axios.get(
            `${baseUrl}/lab/getByDegreeDepartment`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                },
            }
        );

        setCourses(response.data);
        setLoading(false);
    } catch (error) {
        // alert(error.message);
    }
};

// Update Teacher Lab
export const updateTeacherLab = async (
    array,
    teacherId,
    degreeId,
    departmentId,
    accessToken,
    setLoading,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Lab Update Processing...!");

    try {
        const response = await axios.put(
            `${baseUrl}/teacher/lab/update`,
            array,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: { degreeId, departmentId, teacherId },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 5000);
    }
};

// Delete Teachers All Labs
export const deleteTeacherAllLabs = async (
    teacherId,
    accessToken,
    setLoading,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...!");

    try {
        const response = await axios.delete(`${baseUrl}/teacher/lab/delete`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                teacherId,
            },
        });
        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 5000);
    }
};

// Get Courses By Teacher
export const getLabsByTeacher = async (
    degreeId,
    departmentId,
    teacherId,
    setCourses,
    accessToken,
    setLoading
) => {
    try {
        const response = await axios.get(
            `${baseUrl}/teacher/lab/getByTeacher`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    teacherId,
                },
            }
        );

        let newArray = [];
        response.data.forEach((el) => newArray.push(el.lab));

        setCourses(newArray);
        setLoading(false);
    } catch (error) {
        // alert(error.message);
    }
};

// Degree Add
export const degreeAdd = async (
    api,
    extension,
    value,
    accessToken,
    setError,
    setSuccessMsg
) => {
    try {
        const response = await axios.post(
            `${baseUrl}${api}`,
            {
                [extension]: value, //degreeName:value
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setSuccessMsg(response.data);
        setTimeout(() => {
            setSuccessMsg("");
            window.location.reload();
        }, 1000);
    } catch (error) {
        // setError(`Degree Can't add for some reason`);
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Degree Update
export const degreeUpdate = async (
    api,
    extension,
    value,
    extensionId,
    params,
    accessToken,
    setError,
    setSuccessMsg
) => {
    try {
        const response = await axios.put(
            `${baseUrl}${api}`,
            {
                [extension]: value,
            },
            {
                params: {
                    [extensionId]: params,
                },

                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setSuccessMsg(response.data);
        setTimeout(() => {
            setSuccessMsg("");
            window.location.reload();
        }, 1000);
    } catch (error) {
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Department Add
export const departmentAdd = async (
    api,
    extension,
    value,
    paramsIdName,
    paramId,
    accessToken,
    setError,
    setSuccessMsg
) => {
    try {
        const response = await axios.post(
            `${baseUrl}${api}`,
            {
                [extension]: value,
                [paramsIdName]: paramId,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setSuccessMsg(response.data);

        setTimeout(() => {
            setSuccessMsg("");
            window.location.reload();
        }, 1000);
    } catch (error) {
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Department Update
export const departmentUpdate = async (
    api,
    extension,
    value,
    paramsIdName,
    paramId,
    extensionId,
    params,
    accessToken,
    setError,
    setSuccessMsg
) => {
    try {
        const response = await axios.put(
            `${baseUrl}${api}`,
            {
                [extension]: value,
                [paramsIdName]: paramId,
            },
            {
                params: {
                    [extensionId]: params,
                },

                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setSuccessMsg(response.data);
        setTimeout(() => {
            setSuccessMsg("");
            window.location.reload();
        }, 1000);
    } catch (error) {
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Degree Any thing Delete
export const deleteUniversal = async (
    api,
    extensionId,
    id,
    accessToken,
    setError,
    setSuccessMsg
) => {
    try {
        const response = await axios.delete(`${baseUrl}${api}`, {
            params: {
                [extensionId]: id,
            },
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        setSuccessMsg(response.data);
        setTimeout(() => {
            setSuccessMsg("");
            window.location.reload();
        }, 1000);
    } catch (error) {
        setError("Can't Delete for some reason");

        setTimeout(() => setError(""), 3000);
    }
};

// Add New Courses
export const courseAdd = async (
    api,
    courseDetail,
    paramsIdName,
    paramId,
    paramsTwoIdName,
    paramsTwoId,
    boolData,
    optionYear,
    accessToken,
    setError,
    setSuccessMsg
) => {
    try {
        const response = await axios.post(
            `${baseUrl}${api}`,
            {
                courseName: courseDetail.c_name,
                courseCode: courseDetail.c_code,
                [paramsIdName]: paramId,
                [paramsTwoIdName]: paramsTwoId,
                optionalCourse: boolData,
                studentClass: optionYear,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setSuccessMsg(response.data);
        setTimeout(() => {
            setSuccessMsg("");
            window.location.reload();
        }, 1000);
    } catch (error) {
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Update Course
// export const courseUpdate = async (
//     api,
//     courseDetail,
//     paramsIdName,
//     paramId,
//     paramsTwoIdName,
//     paramsTwoId,
//     boolData,
//     optionYear,
//     params,
//     accessToken,
//     setError,
//     setSuccessMsg
// ) => {
//     try {
//         const response = await axios.put(
//             `${baseUrl}${api}`,
//             {
//                 courseName: courseDetail.c_name,
//                 courseCode: courseDetail.c_code,
//                 [paramsIdName]: paramId,
//                 [paramsTwoIdName]: paramsTwoId,
//                 optionalCourse: boolData,
//                 studentClass: optionYear,
//             },
//             {
//                 params: {
//                     courseId: params,
//                 },

//                 headers: {
//                     "ngrok-skip-browser-warning": 123,
//                     "Content-type": "application/json",
//                     Authorization: `Bearer ${accessToken}`,
//                 },
//             }
//         );

//         setSuccessMsg(response.data);
//         setTimeout(() => {
//             setSuccessMsg("");
//             window.location.reload();
//         }, 1000);
//     } catch (error) {
//         setError(error.response.data.message);
//         setTimeout(() => setError(""), 3000);
//     }
// };

// Add New Lab
export const labAdd = async (
    api,
    courseDetail,
    paramsIdName,
    paramId,
    paramsTwoIdName,
    paramsTwoId,
    optionYear,
    accessToken,
    setError,
    setSuccessMsg
) => {
    try {
        const response = await axios.post(
            `${baseUrl}${api}`,
            {
                labName: courseDetail.l_name,
                labCode: courseDetail.l_code,
                courseId: courseDetail.id.c_name,
                [paramsIdName]: paramId,
                [paramsTwoIdName]: paramsTwoId,
                studentClass: optionYear,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setSuccessMsg(response.data);
        setTimeout(() => {
            setSuccessMsg("");
            window.location.reload();
        }, 1000);
    } catch (error) {
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Update Lab
export const labUpdate = async (
    api,
    courseDetail,
    paramsIdName,
    paramId,
    paramsTwoIdName,
    paramsTwoId,
    optionYear,
    params,
    accessToken,
    setError,
    setSuccessMsg
) => {
    try {
        const response = await axios.put(
            `${baseUrl}${api}`,
            {
                labName: courseDetail.l_name,
                labCode: courseDetail.l_code,
                courseId: courseDetail.id.c_name,
                [paramsIdName]: paramId,
                [paramsTwoIdName]: paramsTwoId,
                studentClass: optionYear,
            },
            {
                params: {
                    labId: params,
                },

                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setSuccessMsg(response.data);
        setTimeout(() => {
            setSuccessMsg("");
            window.location.reload();
        }, 1000);
    } catch (error) {
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Take Hsc Student Attendance
export const takeHscAttendance = async (
    array,
    accessToken,
    setLoading,
    setView,
    degreeName,
    degreeId,
    departmentId,
    courseId,
    teacherId,
    studentClass,
    section,
    attendanceTaker,
    attendanceDate,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    // setLoading(true);
    setProcessingMsg("Processing...");
    const api = degreeName === "hsc" ? "hsc" : "bsc";
    try {
        const response = await axios.post(
            `${baseUrl}/${api}/student/attendance/save`,
            array,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    courseId,
                    teacherId,
                    studentClass,
                    section,
                    attendanceTaker,
                    attendanceDate,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 3000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
    }
};

// View student Attendance
export const viewHscStudentAttendance = async (
    accessToken,
    degreeId,
    departmentId,
    courseId,
    date,
    setData,
    setLoading,
    degreeName,
    studentClass
) => {
    setLoading(true);

    const api = degreeName === "hsc" ? "hsc" : "bsc";

    try {
        const response = await axios.get(
            `${baseUrl}/${api}/student/attendance/getByDegreeDepartmentCourseStudentClassSectionDate`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    courseId,
                    date,
                    section: "N/A",
                    studentClass,
                },
            }
        );

        const newArray = [];
        response.data.forEach((el) => {
            if (api === "hsc")
                newArray.push({
                    studentRoll: el.hscStudent.collegeRoll,
                    studentName: el.hscStudent.studentNameEnglish,
                    studentId: el.hscStudentId,
                    attendance: el.attendance,
                    teacherName: el.teacher.teacherName,
                    teacherId: el.teacher.teacherId,
                    attendanceTaker: el.attendanceTaker,
                    attendanceId: el.id,
                    attendanceDate: el.attendanceDate,
                    courseId: el.course.courseId,
                    degreeId: el.degree.degreeId,
                    departmentId: el.department.departmentId,
                });
            else
                newArray.push({
                    studentRoll: el.bscStudent.collegeRoll,
                    studentName: el.bscStudent.studentNameEnglish,
                    studentId: el.bscStudentId,
                    attendance: el.attendance,
                    teacherName: el.teacher.teacherName,
                    teacherId: el.teacher.teacherId,
                    attendanceTaker: el.attendanceTaker,
                    attendanceId: el.id,
                    attendanceDate: el.attendanceDate,
                    courseId: el.course.courseId,
                    degreeId: el.degree.degreeId,
                    departmentId: el.department.departmentId,
                });
        });

        setData(newArray);
        setLoading(false);
    } catch (error) {
        setData([]);
        setLoading(false);
    }
};

// Update Hsc Student Attendance
export const updateHscStudentAttendance = async (
    student,
    attendance,
    accessToken,
    setLoading,
    studentClass,
    setError,
    setSuccessMsg,
    setProcessingMsg,
    degreeName
) => {
    setProcessingMsg("Processing...");
    const api = degreeName === "hsc" ? "hsc" : "bsc";
    const studentId = degreeName === "hsc" ? "hscStudentId" : "bscStudentId";

    try {
        const response = await axios.put(
            `${baseUrl}/${api}/student/attendance/update`,
            {
                degreeId: student.degreeId,
                departmentId: student.departmentId,
                courseId: student.courseId,
                teacherId: student.teacherId,
                [studentId]: student.studentId,
                attendanceTaker: student.attendanceTaker,
                attendanceDate: student.attendanceDate,
                attendance: attendance,
                section: "N/A",
                studentClass,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    id: student.attendanceId,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setSuccessMsg(""), 3000);
        // setLoading(false);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 5000);
    }
};

// Take Lab Attendance
export const takeLabAttendance = async (
    array,
    accessToken,
    setLoading,
    setView,
    degreeName,
    degreeId,
    departmentId,
    labId,
    teacherId,
    studentClass,
    section,
    attendanceTaker,
    attendanceDate,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    // setLoading(true);
    setProcessingMsg("Processing...");
    const api = degreeName === "hsc" ? "hsc" : "bsc";
    try {
        const response = await axios.post(
            `${baseUrl}/${api}/student/lab/attendance/save`,
            array,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    labId,
                    teacherId,
                    studentClass,
                    section,
                    attendanceTaker,
                    attendanceDate,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 3000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
    }
};

// View student Lab Attendance
export const viewStudentLabAttendance = async (
    accessToken,
    degreeId,
    departmentId,
    labId,
    date,
    setData,
    setLoading,
    degreeName,
    studentClass
) => {
    setLoading(true);

    const api = degreeName === "hsc" ? "hsc" : "bsc";

    try {
        const response = await axios.get(
            `${baseUrl}/${api}/student/lab/attendance/getByDegreeDepartmentLabStudentClassSectionDate`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    labId,
                    date,
                    section: "N/A",
                    studentClass,
                },
            }
        );

        const newArray = [];

        response.data.forEach((el) => {
            if (api === "hsc")
                newArray.push({
                    studentRoll: el.hscStudent.collegeRoll,
                    studentName: el.hscStudent.studentNameEnglish,
                    studentId: el.hscStudentId,
                    attendance: el.attendance,
                    teacherName: el.teacher.teacherName,
                    teacherId: el.teacher.teacherId,
                    attendanceTaker: el.attendanceTaker,
                    attendanceId: el.id,
                    attendanceDate: el.attendanceDate,
                    courseId: el.lab.labId,
                    degreeId: el.degree.degreeId,
                    departmentId: el.department.departmentId,
                });
            else
                newArray.push({
                    studentRoll: el.bscStudent.collegeRoll,
                    studentName: el.bscStudent.studentNameEnglish,
                    studentId: el.bscStudentId,
                    attendance: el.attendance,
                    teacherName: el.teacher.teacherName,
                    teacherId: el.teacher.teacherId,
                    attendanceTaker: el.attendanceTaker,
                    attendanceId: el.id,
                    attendanceDate: el.attendanceDate,
                    courseId: el.lab.labId,
                    degreeId: el.degree.degreeId,
                    departmentId: el.department.departmentId,
                });
        });

        setData(newArray);
        setLoading(false);
    } catch (error) {
        setData([]);
        setLoading(false);
    }
};

// Update Hsc Student Attendance
export const updateStudentLabAttendance = async (
    student,
    attendance,
    accessToken,
    setLoading,
    studentClass,
    setError,
    setSuccessMsg,
    setProcessingMsg,
    degreeName
) => {
    setProcessingMsg("Processing...");
    const api = degreeName === "hsc" ? "hsc" : "bsc";
    const studentId = degreeName === "hsc" ? "hscStudentId" : "bscStudentId";
    try {
        const response = await axios.put(
            `${baseUrl}/${api}/student/lab/attendance/update`,
            {
                degreeId: student.degreeId,
                departmentId: student.departmentId,
                labId: student.courseId,
                teacherId: student.teacherId,
                [studentId]: student.studentId,
                attendanceTaker: student.attendanceTaker,
                attendanceDate: student.attendanceDate,
                attendance: attendance,
                section: "N/A",
                studentClass,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    id: student.attendanceId,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setSuccessMsg(""), 3000);

        // setTimeout(() => window.location.reload(), 1000);
        // setLoading(false);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 5000);
    }
};

// Take Teacher Attendance
export const takeTeacherAttendnace = async (
    degreeId,
    departmentId,
    teacherId,
    attendanceDate,
    attendance,
    accessToken,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");

    console.log(attendanceDate);
    try {
        const response = await axios.post(
            `${baseUrl}/teacher/attendance/save`,
            {
                degreeId,
                departmentId,
                teacherId,
                attendanceDate,
                attendance,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 5000);
    }
};

// View Teacher Attendance
export const viewTeacherAttendance = async (
    degreeId,
    departmentId,
    date,
    accessToken,
    setData,
    setLoading,
    teacherId
) => {
    setLoading(true);

    try {
        const response = await axios.get(
            `${baseUrl}/teacher/attendance/getByDegreeDepartmentTeacherDate`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    date,
                    teacherId,
                },
            }
        );

        const newArray = [];
        response.data.forEach((el) => {
            newArray.push({
                teacherCollegeId: el.teacher.teacherCollegeId,
                teacherName: el.teacher.teacherName,
                teacherId: el.teacherId,
                attendance: el.attendance,
                attendanceId: el.id,
                attendanceDate: el.attendanceDate,
                degreeId: el.degree.degreeId,
                departmentId: el.department.departmentId,
            });
        });

        setData(newArray);
        setLoading(false);
    } catch (error) {
        setData([]);
        setLoading(false);
    }
};

// Update Teacher Attendance
export const updateTeacherAttendance = async (
    degreeId,
    departmentId,
    teacherId,
    attendanceDate,
    attendance,
    id,
    accessToken,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.put(
            `${baseUrl}/teacher/attendance/update`,
            {
                degreeId,
                departmentId,
                teacherId,
                attendanceDate,
                attendance,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    id,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 5000);
    }
};

// Generate New Token
export const generateNewToken = async (
    api,
    email,
    accessToken,
    setSuccessMsg,
    setError,
    setProcessingMsg
) => {
    setProcessingMsg("Generating...");
    try {
        const response = await axios.get(
            `${baseUrl}/${api}/student/verification/generateNewVerificationToken`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    email,
                },
            }
        );
        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setSuccessMsg(""), 3000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 5000);
    }
};

// Generate New Token
export const generateTeacherNewToken = async (
    api,
    email,
    accessToken,
    setSuccessMsg,
    setError,
    setProcessingMsg
) => {
    setProcessingMsg("Generating...");
    try {
        const response = await axios.get(
            `${baseUrl}/teacher/verification/generateNewVerificationToken`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    email,
                },
            }
        );
        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setSuccessMsg(""), 3000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 5000);
    }
};

// Promote Teacher
export const promoteTeacher = async (
    accessToken,
    teacherId,
    presentDesignation,
    promotedDesignation,
    setSuccessMsg,
    setError,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.put(
            `${baseUrl}/teacher/promote`,
            {
                presentDesignation,
                promotedDesignation,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    teacherId,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
    }
};

// Take Fee
export const takeStudentFee = async (
    degreeId,
    departmentId,
    studentClass,
    degreeName,
    user,
    accessToken,
    setSuccessMsg,
    setError,
    setProcessingMsg,
    setOption,
    receiptDate,
    paymentType
) => {
    const api = degreeName === "hsc" ? "hsc" : "bsc";
    setProcessingMsg("Processing...");
    try {
        const response = await axios.post(
            `${baseUrl}/${api}/student/fees/save`,
            {
                degreeId,
                departmentId,
                studentClass,
                receiptId: user.receipt_id,
                studentName: user.student_name,
                studentId: user.student_id,
                studentRoll: user.student_roll,
                studentFather: user.student_father,
                paymentType: paymentType,
                session: user.student_session,
                paymentMonth: user.payment_month,
                receiptBy: user.receipt_by,
                currentMonthFee: user.current_month_fee,
                arrears: user.debt,
                advanceFee: user.advance_fee,
                admissionFee: user.admission_fee,
                reAdmissionFee: user.re_admission_fee,
                collegeChangeFee: user.college_change_fee,
                healthCheckFee: user.health_check_fee,
                admissionFormFee: user.admission_form_fee,
                admissionCenterFee: user.admission_center_fee,
                examCenterFee: user.exam_center_fee,
                practicalFee: user.practical_fee,
                certificateFee: user.certificate_fee,
                affiliateFee: user.affiliate_fee,
                identityCardFee: user.identity_card_fee,
                registrationFee: user.registration_fee,
                libraryFee: user.library_fee,
                librarySafetyFee: user.library_safety_fee,
                labSafetyFee: user.lab_safety_fee,
                landLeaseFee: user.land_lease_fee,
                sessionFee: user.session_fee,
                internalExamFee: user.internal_exam_fee,
                subjectChangeFee: user.subject_change_fee,
                commonRoomFee: user.common_room_fee,
                poorFundFee: user.poor_fund_fee,
                magazineFee: user.magazine_fee,
                roverScoutFee: user.rover_scout_fee,
                bnccFee: user.bncc_fee,
                redCrescentFee: user.red_crescent_fee,
                mosqueFee: user.mosque_fee,
                transmissionFee: user.transmission_fee,
                developmentFee: user.development_fee,
                rotatingStaffFee: user.rotating_staff_fee,
                transportFee: user.transport_fee,
                studentParliamentFee: user.student_parliament_fee,
                socialDevelopmentFee: user.social_development_fee,
                culturalFee: user.cultural_fee,
                sportsFee: user.sports_fee,
                outdoorFamesFee: user.outdoor_games_fee,
                studyTourFee: user.study_tour_fee,
                managementFee: user.management_fee,
                ictFee: user.ict_fee,
                scienceClubFee: user.science_club_fee,
                cycleGarageFee: user.cycle_garage_fee,
                miscFee: user.misc_fee,
                websiteFee: user.website_fee,
                totalFee: user.total_fee,
                paymentStatus: user.payment_status,
                receiptDate,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setOption("print fee"), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
    }
};

// Update Fee
export const updateStudentFee = async (
    degreeId,
    departmentId,
    studentClass,
    degreeName,
    user,
    accessToken,
    setSuccessMsg,
    setError,
    setProcessingMsg,
    setOption,
    receiptDate,
    paymentType,
    id
) => {
    const api = degreeName === "hsc" ? "hsc" : "bsc";
    setProcessingMsg("Processing...");
    try {
        const response = await axios.put(
            `${baseUrl}/${api}/student/fees/update`,
            {
                degreeId,
                departmentId,
                studentClass,
                receiptId: user.receipt_id,
                studentName: user.student_name,
                studentId: user.student_id,
                studentRoll: user.student_roll,
                studentFather: user.student_father,
                paymentType: paymentType,
                session: user.student_session,
                paymentMonth: user.payment_month,
                receiptBy: user.receipt_by,
                currentMonthFee: user.current_month_fee,
                arrears: user.debt,
                advanceFee: user.advance_fee,
                admissionFee: user.admission_fee,
                reAdmissionFee: user.re_admission_fee,
                collegeChangeFee: user.college_change_fee,
                healthCheckFee: user.health_check_fee,
                admissionFormFee: user.admission_form_fee,
                admissionCenterFee: user.admission_center_fee,
                examCenterFee: user.exam_center_fee,
                practicalFee: user.practical_fee,
                certificateFee: user.certificate_fee,
                affiliateFee: user.affiliate_fee,
                identityCardFee: user.identity_card_fee,
                registrationFee: user.registration_fee,
                libraryFee: user.library_fee,
                librarySafetyFee: user.library_safety_fee,
                labSafetyFee: user.lab_safety_fee,
                landLeaseFee: user.land_lease_fee,
                sessionFee: user.session_fee,
                internalExamFee: user.internal_exam_fee,
                subjectChangeFee: user.subject_change_fee,
                commonRoomFee: user.common_room_fee,
                poorFundFee: user.poor_fund_fee,
                magazineFee: user.magazine_fee,
                roverScoutFee: user.rover_scout_fee,
                bnccFee: user.bncc_fee,
                redCrescentFee: user.red_crescent_fee,
                mosqueFee: user.mosque_fee,
                transmissionFee: user.transmission_fee,
                developmentFee: user.development_fee,
                rotatingStaffFee: user.rotating_staff_fee,
                transportFee: user.transport_fee,
                studentParliamentFee: user.student_parliament_fee,
                socialDevelopmentFee: user.social_development_fee,
                culturalFee: user.cultural_fee,
                sportsFee: user.sports_fee,
                outdoorFamesFee: user.outdoor_games_fee,
                studyTourFee: user.study_tour_fee,
                managementFee: user.management_fee,
                ictFee: user.ict_fee,
                scienceClubFee: user.science_club_fee,
                cycleGarageFee: user.cycle_garage_fee,
                miscFee: user.misc_fee,
                websiteFee: user.website_fee,
                totalFee: user.total_fee,
                paymentStatus: user.payment_status,
                receiptDate,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },

                params: {
                    id,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setOption("print fee"), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
    }
};

// Get Fee
export const getStudentFee = async (
    degreeId,
    departmentId,
    studentClass,
    paymentType,
    year,
    degreeName,
    accessToken,
    setData,
    setLoading
) => {
    const api = degreeName === "hsc" ? "hsc" : "bsc";
    setLoading(true);
    try {
        const resposne = await axios.get(
            `${baseUrl}/${api}/student/fees/getByDegreeDepartmentStudentClassPaymentTypeYear`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    studentClass,
                    paymentType,
                    year,
                },
            }
        );
        setData(resposne.data);
        setLoading(false);
    } catch (error) {
        setLoading(false);
    }
};

// Update Payment Status
export const updateStatus = async (
    degreeName,
    accessToken,
    receiptId,
    id,
    paymentStatus,
    setSuccessMsg,
    setError,
    setProcessingMsg
) => {
    const api = degreeName === "hsc" ? "hsc" : "bsc";
    setProcessingMsg("Processing...");
    try {
        const response = await axios.put(
            `${baseUrl}/${api}/student/fees/update/paymentStatus`,
            {
                paymentStatus,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    receiptId,
                    id,
                },
            }
        );
        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setSuccessMsg(""), 3000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Delete Fee
export const deleteFee = async (
    degreeName,
    accessToken,
    id,
    setSuccessMsg,
    setError,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");
    const api = degreeName === "hsc" ? "hsc" : "bsc";
    try {
        const response = await axios.delete(
            `${baseUrl}/${api}/student/fees/delete`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    id,
                },
            }
        );
        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => {
            setSuccessMsg("");
            window.location.reload();
        }, 3000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Upload Notice
export const noticeUpload = async (
    formData,
    accessToken,
    noticeTitle,
    uploadDate,
    expireDate,
    setSuccessMsg,
    setError,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");
    console.log(formData);
    try {
        const response = await axios.post(
            `${baseUrl}/media/notice/upload`,
            formData,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    noticeTitle,
                    uploadDate,
                    expireDate,
                },
            }
        );
        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 2000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Get Notice
export const getNotice = async (accessToken, setData, setLoading, count) => {
    setLoading(true);

    try {
        const response = await axios.get(`${baseUrl}/media/notice/all`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                // Authorization: `Bearer ${accessToken}`,
            },
        });

        setData(response.data);
        setLoading(false);
    } catch (error) {
        setTimeout(() => setLoading(false), 3000);
    }
};

// Upload Event
export const eventUpload = async (
    accessToken,
    event,
    uploadDate,
    expireDate,
    setSuccessMsg,
    setError,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.post(
            `${baseUrl}/media/events/save`,
            {
                event,
                uploadDate,
                expireDate,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 2000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Get Event
export const getEvent = async (accessToken, setData, setLoading, count) => {
    setLoading(true);

    try {
        const response = await axios.get(`${baseUrl}/media/events/getAll`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                // Authorization: `Bearer ${accessToken}`,
            },
        });

        setData(response.data);
        setLoading(false);
    } catch (error) {
        setTimeout(() => setLoading(false), 3000);
    }
};

// Delete Event
export const deleteEvent = async (
    accessToken,
    id,
    setSuccessMsg,
    setError,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");

    try {
        const response = await axios.delete(`${baseUrl}/media/events/delete`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                id,
            },
        });

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 3000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Delete Event
export const deleteNotice = async (
    accessToken,
    id,
    setSuccessMsg,
    setError,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");

    try {
        const response = await axios.delete(`${baseUrl}/media/notice/delete`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                id,
            },
        });

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 3000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Student Image Upload
export const ImgUploadAdmin = async (
    degreeName,
    accessToken,
    formData,
    setOption,
    studentId,
    setSuccessMsg,
    setError,
    setProcessingMsg,
    setPreviewSource
) => {
    setProcessingMsg("Processing...");

    const api =
        degreeName.split(" ")[0].toLowerCase() === "hsc" ? "hsc" : "bsc";

    try {
        const response = await axios.post(
            `${baseUrl}/${api}/student/image/save`,
            formData,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    studentId,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        // setTimeout(() => setPreviewSource(null), 2000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Student Image Get
export const ImgGet = async (
    degreeName,
    accessToken,
    studentId,
    setImg,
    setLoading
) => {
    setLoading(true);
    const api =
        degreeName.split(" ")[0].toLowerCase() === "hsc" ? "hsc" : "bsc";
    try {
        const response = await axios.get(
            `${baseUrl}/${api}/student/image/url`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    studentId,
                },
            }
        );

        setImg(response.data);
        setLoading(false);
    } catch (error) {
        setTimeout(() => setLoading(false), 3000);
    }
};

// Student Img Update
export const ImgUpdate = async (
    degreeName,
    accessToken,
    data,
    setOption,
    studentId,
    setSuccessMsg,
    setError,
    setProcessingMsg,
    setPreviewSource
) => {
    setProcessingMsg("Processing...");
    const api =
        degreeName.split(" ")[0].toLowerCase() === "hsc" ? "hsc" : "bsc";

    try {
        const response = await axios.put(
            `${baseUrl}/${api}/student/image/update`,
            data,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    studentId,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        // setTimeout(() => setPreviewSource(null), 2000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Img delete
export const ImgDeleteAdmin = async (
    degreeName,
    accessToken,
    data,
    setOption,
    studentId,
    setSuccessMsg,
    setError,
    setProcessingMsg,
    setPreviewSource
) => {
    setProcessingMsg("Processing...");
    const api =
        degreeName.split(" ")[0].toLowerCase() === "hsc" ? "hsc" : "bsc";

    try {
        const response = await axios.delete(
            `${baseUrl}/${api}/student/image/delete`,
            data,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    studentId,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Teacher Image Upload
export const teacherImgUpload = async (
    accessToken,
    formData,
    setOption,
    teacherId,
    setSuccessMsg,
    setError,
    setProcessingMsg,
    setPreviewSource
) => {
    setProcessingMsg("Processing...");

    try {
        const response = await axios.post(
            `${baseUrl}/teacher/image/save`,
            formData,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    teacherId,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 2000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Student Image Get
export const teacherImgGet = async (
    degreeName,
    accessToken,
    teacherId,
    setImg,
    setLoading
) => {
    setLoading(true);

    try {
        const response = await axios.get(`${baseUrl}/teacher/image/url`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                teacherId,
            },
        });

        setImg(response.data);
        setLoading(false);
    } catch (error) {
        setTimeout(() => setLoading(false), 3000);
    }
};

// Student Img Update
export const teacherImgUpdate = async (
    accessToken,
    data,
    setOption,
    teacherId,
    setSuccessMsg,
    setError,
    setProcessingMsg,
    setPreviewSource
) => {
    setProcessingMsg("Processing...");

    try {
        const response = await axios.put(
            `${baseUrl}/teacher/image/update`,
            data,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    teacherId,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 2000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Img delete
export const teacherImgDelete = async (
    degreeName,
    accessToken,
    data,
    setOption,
    teacherId,
    setSuccessMsg,
    setError,
    setProcessingMsg,
    setPreviewSource
) => {
    setProcessingMsg("Processing...");

    try {
        const response = await axios.delete(
            `${baseUrl}/teacher/image/delete`,
            data,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    teacherId,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Get Home Data
export const getAllHomeData = async (
    accessToken,
    date,
    year,
    setData,
    setLoading
) => {
    setLoading(true);
    try {
        const response = await axios.get(`${baseUrl}/dashboard/data`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                date,
                year,
            },
        });

        setData(response.data);
        setLoading(false);
    } catch (error) {
        setLoading(false);
    }
};

// Montyhly Test Addition
export const addStudentMonthlyTest = async (
    marks,
    degreeName,
    degreeId,
    departmentId,
    courseId,
    semester,
    studentClass,
    monthlyTestNumber,
    examDate,
    teacherId,
    accessToken,
    setSuccessMsg,
    setError,
    setProcessingMsg,
    setOption
) => {
    const api =
        degreeName.split(" ")[0].toLowerCase() === "hsc" ? "hsc" : "bsc";

    setProcessingMsg("Processing...");
    try {
        const response = await axios.post(
            `${baseUrl}/${api}/result/monthlyTest/save`,
            marks,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    courseId,
                    semester,
                    studentClass,
                    section: "N/A",
                    monthlyTestNumber,
                    examDate,
                    teacherId,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setOption("print mark"), 2000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Montyhly Test Addition
export const addStudentSemesterTest = async (
    marks,
    degreeName,
    degreeId,
    departmentId,
    courseId,
    semester,
    studentClass,
    examDate,
    teacherId,
    accessToken,
    setSuccessMsg,
    setError,
    setProcessingMsg,
    setOption
) => {
    const api =
        degreeName.split(" ")[0].toLowerCase() === "hsc" ? "hsc" : "bsc";

    setProcessingMsg("Processing...");

    console.log(marks);
    try {
        const response = await axios.post(
            `${baseUrl}/${api}/result/semester/save`,
            marks,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    courseId,
                    semester,
                    studentClass,
                    section: "N/A",
                    examDate,
                    teacherId,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setOption("print mark"), 2000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Lab Test Addition
export const addStudentLabTest = async (
    marks,
    degreeName,
    degreeId,
    departmentId,
    labId,
    semester,
    studentClass,
    examDate,
    teacherId,
    accessToken,
    setSuccessMsg,
    setError,
    setProcessingMsg,
    setOption
) => {
    const api =
        degreeName.split(" ")[0].toLowerCase() === "hsc" ? "hsc" : "bsc";

    setProcessingMsg("Processing...");
    try {
        const response = await axios.post(
            `${baseUrl}/${api}/result/lab/save`,
            marks,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    labId,
                    semester,
                    studentClass,
                    section: "N/A",
                    examDate,
                    teacherId,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setOption("print mark"), 2000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Get Lab Test Result   ***** Have studentId problem
export const getStudentLabTest = async (
    degreeName,
    degreeId,
    departmentId,
    courseId,
    semester,
    studentClass,
    examDate,
    studentId,
    accessToken,
    setData,
    setLoading
) => {
    const api =
        degreeName.split(" ")[0].toLowerCase() === "hsc" ? "hsc" : "bsc";

    setLoading(true);
    try {
        const response = await axios.get(
            `${baseUrl}/${api}/result/lab/getByDegreeDepartmentLabSemesterStudentClassSectionYearStudent`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    courseId,
                    semester,
                    studentClass,
                    section: "N/A",
                    studentId,
                    year: new Date(examDate).getFullYear(),
                },
            }
        );

        setData((prevState) => ({
            ...prevState,
            labMark: response.data,
        }));
        setLoading(false);
    } catch (error) {
        setLoading(false);
    }
};

// Get Monthly Test Avg
export const getStudentMonthlyTestAvg = async (
    degreeName,
    degreeId,
    departmentId,
    courseId,
    semester,
    studentId,
    examDate,
    accessToken,
    setData,
    setLoading
) => {
    const api =
        degreeName.split(" ")[0].toLowerCase() === "hsc" ? "hsc" : "bsc";

    setLoading(true);
    try {
        const response = await axios.get(
            `${baseUrl}/${api}/result/monthlyTest/getAverage`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    courseId,
                    semester,
                    studentId,
                    year: new Date(examDate).getFullYear(),
                },
            }
        );
        setData((prevState) => ({ ...prevState, markAvg: response.data }));
        setLoading(false);
    } catch (error) {
        setLoading(false);
    }
};

// Get Monthly Test For All Students
export const getStudentMonthlyTest = async (
    degreeName,
    degreeId,
    departmentId,
    courseId,
    semester,
    studentClass,
    monthlyTestNumber,
    examDate,
    accessToken,
    setData,
    setLoading,
    setDefaultOption
) => {
    const api =
        degreeName.split(" ")[0].toLowerCase() === "hsc" ? "hsc" : "bsc";

    setLoading(true);
    try {
        const response = await axios.get(
            `${baseUrl}/${api}/result/monthlyTest/getByDegreeDepartmentCourseSemesterStudentClassSectionMonthlyTestNumberYear`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    courseId,
                    semester,
                    studentClass,
                    section: "N/A",
                    monthlyTestNumber,
                    year: new Date(examDate).getFullYear(),
                },
            }
        );

        setData(response.data);
        setDefaultOption((prevState) => ({
            ...prevState,
            show: true,
            title: {
                ...prevState.title,
                teacher_id: response.data[0].teacher.teacherName,
            },
        }));
        setLoading(false);
    } catch (error) {
        setLoading(false);
    }
};

// Get Lab Test For All Students
export const getStudentLabTestIndividual = async (
    degreeName,
    degreeId,
    departmentId,
    labId,
    semester,
    studentClass,
    year,
    isPublished,
    accessToken,
    setData,
    setLoading,
    setDefaultOption
) => {
    const api =
        degreeName.split(" ")[0].toLowerCase() === "hsc" ? "hsc" : "bsc";

    setLoading(true);
    try {
        const response = await axios.get(
            `${baseUrl}/${api}/result/lab/getByDegreeDepartmentLabSemesterStudentClassSectionYearPublished`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    labId,
                    semester,
                    studentClass,
                    section: "N/A",
                    year,
                    isPublished,
                },
            }
        );

        setData(response.data);
        setDefaultOption((prevState) => ({
            ...prevState,
            show: true,
            title: {
                ...prevState.title,
                teacher_id: response.data[0].teacher.teacherName,
            },
        }));
        setLoading(false);
    } catch (error) {
        setLoading(false);
        setData([]);
        setDefaultOption((prevState) => ({
            ...prevState,
            show: true,
            title: {
                ...prevState.title,
                teacher_id: "No Teacher Found",
            },
        }));
    }
};

// Get Semester Test For All Students
export const getStudentSemesterTestIndividual = async (
    degreeName,
    degreeId,
    departmentId,
    courseId,
    semester,
    studentClass,
    year,
    isPublished,
    accessToken,
    setData,
    setLoading,
    setDefaultOption
) => {
    const api =
        degreeName.split(" ")[0].toLowerCase() === "hsc" ? "hsc" : "bsc";

    setLoading(true);
    try {
        const response = await axios.get(
            `${baseUrl}/${api}/result/semester/getByDegreeDepartmentCourseSemesterStudentClassSectionYearPublished`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    courseId,
                    semester,
                    studentClass,
                    section: "N/A",
                    year,
                    isPublished,
                },
            }
        );

        setData(response.data);
        setDefaultOption((prevState) => ({
            ...prevState,
            show: true,
            title: {
                ...prevState.title,
                teacher_id: response.data[0].teacher.teacherName,
            },
        }));
        setLoading(false);
    } catch (error) {
        setLoading(false);
        setData([]);
        setDefaultOption((prevState) => ({
            ...prevState,
            show: true,
            title: {
                ...prevState.title,
                teacher_id: "No Teacher Found",
            },
        }));
    }
};

// Update Monthly Test For All Student
export const updateMonthlyTest = async (
    degreeName,
    id,
    degreeId,
    departmentId,
    courseId,
    monthlyTestNumber,
    studentClass,
    obj,
    accessToken,
    setSuccessMsg,
    setError,
    setProcessingMsg
) => {
    const api =
        degreeName.split(" ")[0].toLowerCase() === "hsc" ? "hsc" : "bsc";

    setProcessingMsg("Processing...");

    try {
        const response = await axios.put(
            `${baseUrl}/${api}/result/monthlyTest/update`,
            {
                degreeId,
                departmentId,
                courseId,
                semester: obj.semester,
                monthlyTestNumber,
                teacherId: parseInt(obj.teacher_id),
                studentId: parseInt(obj.student_id),
                totalMarks: parseFloat(obj.total_mark),
                achievedMarks: parseFloat(obj.student_mark),
                studentClass,
                section: "N/A",
                grade: obj.student_grade,
                passStatus: obj.student_pass_status,
                examDate: obj.exam_date,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    id,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setSuccessMsg(""), 2000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Update Lab Test For All Student
export const updateLabTest = async (
    degreeName,
    id,
    degreeId,
    departmentId,
    labId,
    studentClass,
    obj,
    accessToken,
    setSuccessMsg,
    setError,
    setProcessingMsg
) => {
    const api =
        degreeName.split(" ")[0].toLowerCase() === "hsc" ? "hsc" : "bsc";
    setProcessingMsg("Processing...");

    try {
        const response = await axios.put(
            `${baseUrl}/${api}/result/lab/update`,
            {
                degreeId,
                departmentId,
                labId,
                semester: obj.semester,
                studentClass,
                section: "N/A",
                teacherId: parseInt(obj.teacher_id),
                studentId: parseInt(obj.student_id),
                totalPracticalMarks: parseFloat(obj.total_practical_mark),
                achievedPracticalMarks: parseFloat(obj.practical_mark),
                totalVivaMarks: parseInt(obj.total_viba_mark),
                achievedVivaMarks: parseInt(obj.viba_mark),
                totalLabExamMarks: parseInt(obj.total_mark),
                totalAcheivedMarks: parseInt(obj.student_mark),
                grade: obj.student_grade,
                passStatus: obj.student_pass_status,
                isPublished: obj.publishStatus,
                examDate: obj.exam_date,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    id,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setSuccessMsg(""), 2000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

// Update Lab Test For All Student
export const updateSemesterTest = async (
    degreeName,
    id,
    degreeId,
    departmentId,
    courseId,
    studentClass,
    obj,
    accessToken,
    setSuccessMsg,
    setError,
    setProcessingMsg
) => {
    const api =
        degreeName.split(" ")[0].toLowerCase() === "hsc" ? "hsc" : "bsc";
    setProcessingMsg("Processing...");
    try {
        const response = await axios.put(
            `${baseUrl}/${api}/result/semester/update`,
            {
                degreeId,
                departmentId,
                courseId,
                semester: obj.semester,
                studentClass,
                section: "N/A",
                teacherId: parseInt(obj.teacher_id),
                studentId: parseInt(obj.student_id),
                writtenFullMarks: parseInt(obj.total_cq_mark),
                writtenAchievedMarks: parseInt(obj.cq_mark),
                mcqFullMarks: parseInt(obj.total_mcq_mark),
                mcqAchievedMarks: parseInt(obj.mcq_mark),
                monthlyTestMarks: parseInt(obj.monthly_test_mark),
                labTestMarks: parseInt(obj.lab_test_mark),
                totalMarks: parseInt(obj.total_mark),
                achievedMarks: parseInt(obj.student_mark),
                grade: obj.student_grade,
                passStatus: obj.student_pass_status,
                examDate: obj.exam_date,
                isPublished: obj.publishStatus,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    id,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setSuccessMsg(""), 2000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

export const adminSaveStudent = async (
    name,
    email,
    degreeId,
    departmentId,
    studentClass,
    session,
    collegeRoll,
    accessToken,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.post(
            `${baseUrl}/admin/admission/student/save`,
            {
                name,
                email,
                degreeId,
                departmentId,
                studentClass,
                section: "N/A",
                session,
                collegeRoll,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

export const adminSaveTeacher = async (
    name,
    email,
    degreeId,
    departmentId,
    joinDate,
    teacherCollegeId,
    designation,
    accessToken,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.post(
            `${baseUrl}/admin/admission/teacher/save`,
            {
                name,
                email,
                degreeId,
                departmentId,
                joinDate,
                teacherCollegeId,
                designation,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

export const adminUpdateStudent = async (
    name,
    email,
    degreeId,
    departmentId,
    studentClass,
    session,
    collegeRoll,
    id,
    accessToken,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.put(
            `${baseUrl}/admin/admission/student/update`,
            {
                name,
                email,
                degreeId,
                departmentId,
                studentClass,
                section: "N/A",
                session,
                collegeRoll,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    id,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

export const adminUpdateTeacher = async (
    name,
    email,
    degreeId,
    departmentId,
    joinDate,
    teacherCollegeId,
    designation,
    id,
    accessToken,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.put(
            `${baseUrl}/admin/admission/teacher/update`,
            {
                name,
                email,
                degreeId,
                departmentId,
                joinDate,
                teacherCollegeId,
                designation,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    id,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.response.data.message);
        setTimeout(() => setError(""), 3000);
    }
};

export const getAdmissionStudent = async (accessToken, setStudents) => {
    try {
        const response = await axios.get(
            `${baseUrl}/admin/admission/student/list-all`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setStudents(response.data);
    } catch (error) {}
};

export const getAdmissionTeacher = async (accessToken, setStudents) => {
    try {
        const response = await axios.get(
            `${baseUrl}/admin/admission/teacher/list-all`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setStudents(response.data);
    } catch (error) {}
};

export const studentAdmissionDelete = async (
    accessToken,
    id,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.delete(
            `${baseUrl}/admin/admission/student/delete`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    id,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.message);
        setTimeout(() => setError(""), 3000);
    }
};

export const teacherAdmissionDelete = async (
    accessToken,
    id,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.delete(
            `${baseUrl}/admin/admission/teacher/delete`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    id,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.message);
        setTimeout(() => setError(""), 3000);
    }
};

export const resendStudentVerifyToken = async (
    accessToken,
    email,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.get(
            `${baseUrl}/admin/admission/student/new-verification-token`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    email,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setSuccessMsg(""), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.message);
        setTimeout(() => setError(""), 3000);
    }
};

export const resendTeacherVerifyToken = async (
    accessToken,
    email,
    setError,
    setSuccessMsg,
    setProcessingMsg
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.get(
            `${baseUrl}/admin/admission/teacher/new-verification-token`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    email,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setSuccessMsg(""), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.message);
        setTimeout(() => setError(""), 3000);
    }
};

export const unpublishedResults = async (
    degreeId,
    departmentId,
    courseId,
    semester,
    studentClass,
    year,
    accessToken,
    setData
) => {
    try {
        const response = await axios.get(
            `${baseUrl}/admin/academic-bulk-action/results/unpublished`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    courseId,
                    semester,
                    studentClass,
                    section: "N/A",
                    year,
                    isPublished: false,
                },
            }
        );

        setData(response.data);
    } catch (error) {}
};

export const publishResults = async (
    degreeId,
    departmentId,
    courseId,
    semester,
    studentClass,
    year,
    accessToken,
    setProcessingMsg,
    setSuccessMsg,
    setError
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.put(
            `${baseUrl}/admin/academic-bulk-action/results/publish-result`,
            {
                isPublished: true,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    courseId,
                    semester,
                    studentClass,
                    section: "N/A",
                    year,
                    isPublished: false,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setSuccessMsg(""), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.message);
        setTimeout(() => setError(""), 3000);
    }
};

export const unPromotedStudents = async (
    degreeId,
    departmentId,
    studentClass,
    year,
    accessToken,
    setData
) => {
    try {
        const response = await axios.get(
            `${baseUrl}/admin/academic-bulk-action/pass-count`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    studentClass,
                    section: "N/A",
                    year,
                    passStatus: "Pass",
                },
            }
        );

        setData(response.data);
    } catch (error) {}
};

export const promotStudents = async (
    promotedClass,
    degreeId,
    departmentId,
    studentClass,
    year,
    accessToken,
    setProcessingMsg,
    setSuccessMsg,
    setError
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.put(
            `${baseUrl}/admin/academic-bulk-action/promote`,
            {
                currentClass: studentClass,
                promotedClass,
                promotedSection: "N/A",
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    degreeId,
                    departmentId,
                    studentClass,
                    section: "N/A",
                    year,
                    passStatus: "Pass",
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => setSuccessMsg(""), 1000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.message);
        setTimeout(() => setError(""), 3000);
    }
};

export const changeAdminPassword = async (
    accessToken,
    email,
    oldPassword,
    newPassword,
    setProcessingMsg,
    setSuccessMsg,
    setError
) => {
    setProcessingMsg("Processing...");
    try {
        const response = await axios.put(
            `${baseUrl}/admin/password-new`,
            {
                email,
                oldPassword,
                newPassword,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => {
            setSuccessMsg("");
            Cookies.remove("user");
            window.location.href = "/sign-in";
        }, 2000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.message);
        setTimeout(() => setError(""), 3000);
    }
};

export const getHscProfile = async (accessToken, setData, studentId) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/v1/hsc/student/profile`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    studentId,
                },
            }
        );

        setData(response.data);
    } catch (error) {
        setData([]);
    }
};

export const getDegreeProfile = async (accessToken, studentId, setData) => {
    try {
        const response = await axios.get(
            `${baseUrl}/api/v1/bsc/student/profile`,
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    studentId,
                },
            }
        );

        setData(response.data);
    } catch (error) {
        setData([]);
    }
};

export const getTeacherProfile = async (accessToken, teacherId, setData) => {
    try {
        const response = await axios.get(`${baseUrl}/api/v1/teacher/profile`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                teacherId,
            },
        });

        setData(response.data);
    } catch (error) {
        setData([]);
    }
};

export const courseSave = async (
    accessToken,
    degreeId,
    departmentId,
    studentClass,
    courseName,
    courseCode,
    compulsoryCourse,
    optionalCourse,
    setProcessing,
    setSuccess,
    setError
) => {
    setProcessing("Processing...");
    try {
        const response = await axios.post(
            `${baseUrl}/course/save`,
            {
                degreeId,
                departmentId,
                studentClass,
                courseName,
                courseCode,
                compulsoryCourse,
                optionalCourse,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        setProcessing("");
        setSuccess(response.data);

        setTimeout(() => {
            window.location.reload();
        }, 3000);
    } catch (error) {
        setProcessing("");
        setError(error.message);
        setTimeout(() => setError(""), 3000);
    }
};

export const deleteCourse = async (
    courseId,
    accessToken,
    setProcessingMsg,
    setSuccessMsg,
    setError
) => {
    setProcessingMsg("Processing...");

    try {
        const response = await axios.delete(`${baseUrl}/course/delete`, {
            headers: {
                "ngrok-skip-browser-warning": 123,
                "Content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                courseId,
            },
        });

        setProcessingMsg("");
        setSuccessMsg(response.data);
        setTimeout(() => {
            setSuccessMsg("");
            window.location.href = "/admin-dashboard/courses";
        }, 3000);
    } catch (error) {
        setProcessingMsg("");
        setError(error.message);
        setTimeout(() => setError(""), 3000);
    }
};

export const courseUpdate = async (
    accessToken,
    degreeId,
    departmentId,
    studentClass,
    courseName,
    courseCode,
    compulsoryCourse,
    optionalCourse,
    setProcessing,
    setSuccess,
    setError,
    courseId
) => {
    setProcessing("Processing...");
    try {
        const response = await axios.put(
            `${baseUrl}/course/update`,
            {
                degreeId,
                departmentId,
                studentClass,
                courseName,
                courseCode,
                compulsoryCourse,
                optionalCourse,
            },
            {
                headers: {
                    "ngrok-skip-browser-warning": 123,
                    "Content-type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                params: {
                    courseId,
                },
            }
        );

        setProcessing("");
        setSuccess(response.data);

        setTimeout(() => {
            window.location.reload();
        }, 3000);
    } catch (error) {
        setProcessing("");
        setError(error.message);
        setTimeout(() => setError(""), 3000);
    }
};
