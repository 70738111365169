import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import { useAuth } from "./context/Context";

const Layout = ({ children }) => {
    const [windowPosition, setWindowPosition] = useState(0);
    const { lang, setLang } = useAuth();
    const location = useLocation();
    const showNav = !location.pathname.includes("dashboard");

    const handleScroll = useCallback(() => {
        setWindowPosition(window.scrollY);
    }, []);

    useEffect(() => {
        const images = document.querySelectorAll("img");
        images.forEach((img) => {
            img.addEventListener("mousedown", (e) => {
                e.preventDefault();
            });
        });

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScroll]);

    useEffect(() => {
        const root = document.querySelector("#root");
        if (showNav) {
            root.classList.toggle("scroll__active", windowPosition > 50);
        }
    }, [showNav, windowPosition]);

    return (
        <div className={`${lang === "EN" ? "english" : "bangla"}`}>
            {showNav ? <Navbar lang={lang} setLang={setLang} /> : null}
            <main>{children}</main>
            {showNav ? <Footer lang={lang} /> : null}
        </div>
    );
};

export default Layout;
