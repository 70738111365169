import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DataProvider } from "./context/Context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <DataProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </DataProvider>
);

reportWebVitals();
