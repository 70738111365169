import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ToggleBar = ({ openMenu }) => {
    return (
        <div onClick={openMenu} className="toggle__bar">
            <LazyLoadImage
                src="https://cdn.shopify.com/s/files/1/1706/9177/t/11/assets/jas-hamburger-black.svg"
                alt="toggle__bar"
            />
        </div>
    );
};

export default ToggleBar;
