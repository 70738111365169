import React from "react";
import Link from "../../link/Link";

const DropdownPrototype = ({ to, child, arr, closeMenu, menuClick, lang }) => {
    return (
        <li>
            <div className="anchr">
                <Link closeMenu={closeMenu} to={to} child={child} />
                <i onClick={menuClick} className="fa-solid fa-plus"></i>
                <i className="fa-solid fa-angle-right"></i>
            </div>

            <div className="sub__dropdown">
                {arr.map((link, index) => (
                    <Link
                        key={index}
                        to={link.to}
                        closeMenu={closeMenu}
                        child={lang === "EN" ? link.child.en : link.child.bn}
                    />
                ))}
            </div>
        </li>
    );
};

export default DropdownPrototype;
