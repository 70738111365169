import React from "react";
import { NavLink } from "react-router-dom";

const TopFooterContent = ({ lang }) => {
    return (
        <div className="top">
            <h3>{lang === "EN" ? "QUICK LINKS" : "গুরুত্বপূর্ণ লিঙ্ক"}</h3>
            <ul className="links">
                <li>
                    <a href=" http://www.educationboard.gov.bd/">
                        {lang === "EN" ? "Education Board" : "শিক্ষা বোর্ড"}
                    </a>
                </li>
                <span>|</span>
                <li>
                    <a href=" http://www.dinajpureducationboard.gov.bd/">
                        {lang === "EN"
                            ? "Dinajpur Education borad"
                            : "দিনাজপুর শিক্ষা বোর্ড "}
                    </a>
                </li>
                <span>|</span>
                <li>
                    <a href="http://www.educationboardresults.gov.bd/">
                        {lang === "EN"
                            ? "Dinajpur Education board result"
                            : "দিনাজপুর শিক্ষা বোর্ডের ফলাফল"}
                    </a>
                </li>
                <span>|</span>
                <li>
                    <a href="http://dhakaeducationboard.portal.gov.bd/">
                        {lang === "EN"
                            ? " Dhaka Education board"
                            : "ঢাকা শিক্ষা বোর্ড"}
                    </a>
                </li>
                <span>|</span>
                <li>
                    <NavLink reloadDocument to="https://moedu.gov.bd/">
                        {lang === "EN"
                            ? "Ministry of Education Government of the People's Republic of Bangladesh"
                            : "শিক্ষা মন্ত্রণালয় গণপ্রজাতন্ত্রী বাংলাদেশ সরকার"}
                    </NavLink>
                </li>
                <span>|</span>
                <li>
                    <NavLink
                        reloadDocument
                        to="https://dinajpureducationboard.gov.bd/"
                    >
                        {lang === "EN"
                            ? "Board of Secondary and Higher Secondary Education, Dinajpur"
                            : "মাধ্যমিক ও উচ্চ মাধ্যমিক শিক্ষা বোর্ড, দিনাজপুর"}
                    </NavLink>
                </li>
                <span>|</span>
                <li>
                    <NavLink reloadDocument to="https://shed.portal.gov.bd/">
                        {lang === "EN"
                            ? "Department of Secondary and Higher Education"
                            : "মাধ্যমিক ও উচ্চ শিক্ষা বিভাগ"}
                    </NavLink>
                </li>
                <span>|</span>
                <li>
                    <NavLink reloadDocument to="https://dshe.gov.bd/">
                        {lang === "EN"
                            ? "Department of Secondary and Higher Education"
                            : "মাধ্যমিক ও উচ্চ শিক্ষা অধিদপ্তর"}
                    </NavLink>
                </li>
                <span>|</span>
                <li>
                    <NavLink reloadDocument to="/visit-us">
                        {lang === "EN" ? "CONTACT US" : "যোগাযোগ করুন"}
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};

export default TopFooterContent;
