import React from "react";

const MiddleFooterContent = ({ lang }) => {
    return (
        <div className="middle">
            <h3>{lang === "EN" ? "FOLLOW US" : "আমাদের অনুসরণ করুন"}</h3>
            <ul className="links">
                <li>
                    <a href="">
                        <i className="fa-brands fa-facebook-f"></i>
                    </a>
                </li>
                <li>
                    <a href="">
                        <i className="fa-brands fa-twitter"></i>
                    </a>
                </li>
                <li>
                    <a href="">
                        <i className="fa-brands fa-instagram"></i>
                    </a>
                </li>
                <li>
                    <a href="">
                        <i className="fa-brands fa-linkedin"></i>
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default MiddleFooterContent;
