import React from "react";
import LanguageBar from "./languageBar/LanguageBar";
import AboutUsLink from "./links/AboutUsLink";
import AcademicLink from "./links/AcademicLink";
import BangabandhuCornerLink from "./links/BangabandhuCornerLink";
import HomeLink from "./links/HomeLink";
import LoginLink from "./links/LoginLink";
import VisitUsLink from "./links/VisitUsLink";
import Logo from "./logo/Logo";
import MenuHead from "./menuHead/MenuHead";
import ToggleBar from "./toggleBar/ToggleBar";
import { useAuth } from "../../../context/Context";
import UserBar from "./userBar/UserBar";
import Forms from "./links/Forms";

const NavbarContent = ({
    lang,
    menu,
    closeMenu,
    openMenu,
    menuClick,
    backClick,
    handleBangla,
    handleEnglish,
}) => {
    const { user } = useAuth();
    return (
        <div className="navbar__content">
            <ToggleBar openMenu={openMenu} />

            <Logo lang={lang} closeMenu={closeMenu} />

            <div className={`nav__links ${menu}`}>
                <MenuHead
                    lang={lang}
                    backClick={backClick}
                    closeMenu={closeMenu}
                />

                <ul>
                    <HomeLink lang={lang} closeMenu={closeMenu} />

                    <AcademicLink
                        lang={lang}
                        menuClick={menuClick}
                        closeMenu={closeMenu}
                    />

                    <AboutUsLink lang={lang} closeMenu={closeMenu} />

                    <VisitUsLink lang={lang} closeMenu={closeMenu} />

                    <BangabandhuCornerLink lang={lang} closeMenu={closeMenu} />

                    <Forms lang={lang} closeMenu={closeMenu} />

                    {!user.name && (
                        <LoginLink lang={lang} closeMenu={closeMenu} />
                    )}
                </ul>
                {user.name !== "" && <UserBar cls="desktop__none" />}
            </div>

            <LanguageBar
                handleBangla={handleBangla}
                handleEnglish={handleEnglish}
                user={user}
            />
        </div>
    );
};

export default NavbarContent;
