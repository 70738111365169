import React from "react";
import Link from "./link/Link";

const VisitUsLink = ({ closeMenu, lang }) => {
    return (
        <li>
            <Link
                closeMenu={closeMenu}
                to="/visit-us"
                child={lang === "EN" ? "Visit us" : "আমাদের পরিদর্শন করুন"}
            />
        </li>
    );
};

export default VisitUsLink;
