import React from "react";

const MenuHead = ({ backClick, closeMenu, lang }) => {
    return (
        <div className="menu__head">
            <i
                onClick={backClick}
                className="fa-solid fa-chevron-left back__btn"
            ></i>
            <h1 id="menu" className="menu__name">
                {lang === "EN" ? "Menu" : "মেনু"}
            </h1>
            <i onClick={closeMenu} className="fa-solid fa-xmark close__btn"></i>
        </div>
    );
};

export default MenuHead;
