import React, { useEffect, useState } from "react";
import { noticeUpload } from "../../../../../../../api/admin/admin";
import { useAuth } from "../../../../../../../context/Context";
import SelectDate from "../../../../../common/SelectDate";

const AddNotice = ({ handleClose }) => {
    const { user } = useAuth();
    const [show, setShow] = useState(false);

    const [error, setError] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [processingMsg, setProcessingMsg] = useState("");

    const [formData, setFormData] = useState(null);

    const [obj, setObj] = useState({
        title: "",
        file: null,
        date: "N/A",
    });

    const handleChange = (e) => {
        setObj((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleFile = (e) => {
        setObj((prevState) => ({
            ...prevState,
            file: e.target.files[0],
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        noticeUpload(
            formData,
            user.accessToken,
            obj.title,
            new Date().toDateString(),
            obj.date,
            setSuccessMsg,
            setError,
            setProcessingMsg
        );
    };

    const handleDate = () => {
        show ? setShow(false) : setShow(true);
    };

    useEffect(() => {
        if (obj.file) {
            const data = new FormData();
            data.append("file", obj.file);
            setFormData(data);
        }
    }, [obj.file]);

    return (
        <div className="form__wrapper">
            <i onClick={handleClose} className="fa-solid fa-xmark"></i>
            <div className="heading">Add a New Notice</div>

            {error !== "" && (
                <div
                    style={{
                        position: "sticky",
                        right: "0",
                        top: "-3rem",
                        width: "100%",
                        fontSize: "1.6rem",
                        padding: "1rem 2rem",
                        textAlign: "center",
                        zIndex: "10",
                    }}
                    className="alert alert-danger"
                    role="alert"
                >
                    {error}
                </div>
            )}

            {processingMsg !== "" && (
                <div
                    style={{
                        position: "sticky",
                        right: "0",
                        top: "-3rem",
                        width: "100%",
                        fontSize: "1.6rem",
                        padding: "1rem 2rem",
                        textAlign: "center",
                        zIndex: "10",
                    }}
                    className="alert alert-warning"
                    role="alert"
                >
                    {processingMsg}
                    <div
                        style={{
                            marginLeft: "1rem",
                        }}
                        className="spinner-border text-warning"
                        role="status"
                    ></div>
                </div>
            )}

            {successMsg !== "" && (
                <div
                    style={{
                        position: "sticky",
                        right: "0",
                        top: "-3rem",
                        width: "100%",
                        fontSize: "1.6rem",
                        padding: "1rem 2rem",
                        textAlign: "center",
                        zIndex: "10",
                    }}
                    className="alert alert-success"
                    role="alert"
                >
                    {successMsg}
                </div>
            )}

            <form className="form__box" onSubmit={handleSubmit}>
                <input
                    type="text"
                    onChange={handleChange}
                    value={obj.title}
                    name="title"
                    placeholder="Title:"
                />
                <input type="file" name="file" onChange={handleFile} />
                <SelectDate
                    handleDate={handleDate}
                    show={show}
                    user={obj.date === "N/A" ? "Select Date  +" : obj.date}
                    setUser={setObj}
                    setShow={setShow}
                    title="Expire Date:"
                    cls="normal"
                    // location="form"
                />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default AddNotice;
