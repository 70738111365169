import React from "react";
import FooterContent from "./elements/FooterContent";
import SubFooter from "./elements/SubFooter";
import "../../css/footer/footer.scss";

const Footer = ({ lang }) => {
    return (
        <div className="footer__wrapper">
            <SubFooter />
            <FooterContent lang={lang} />
        </div>
    );
};

export default Footer;
