import React from "react";
import Link from "./link/Link";

const HomeLink = ({ closeMenu, lang }) => {
    return (
        <li>
            <Link
                closeMenu={closeMenu}
                to="/"
                child={lang === "EN" ? "Home" : "হোম"}
            />
        </li>
    );
};

export default HomeLink;
