import React from "react";
import Link from "./link/Link";

const LoginLink = ({ closeMenu, lang }) => {
    return (
        <li>
            <Link
                closeMenu={closeMenu}
                to="/sign-in"
                child={lang === "EN" ? "Login" : "লগইন"}
            />
        </li>
    );
};

export default LoginLink;
