import React, { useContext, useEffect, useState } from "react";
import { getUser } from "../api/admin/admin";
import Cookies from "js-cookie";

const DataContext = React.createContext();

export const useAuth = () => {
    return useContext(DataContext);
};

export const DataProvider = ({ children }) => {
    const [lang, setLang] = useState("বাং");

    const [loading, setLoading] = useState(true);

    const [user, setUser] = useState({
        name: "",
        email: "",
        api: "",
        accessToken: "",
        details: {},
    });

    const [attendanceDetails, setAttendanceDetails] = useState({
        id: {
            degree_id: "",
            department_id: "",
            course_id: "",
            teacher_id: "",
            year: "",
        },
        name: {
            degree_id: "",
            department_id: "",
            course_id: "",
            teacher_id: "",
            year: "",
        },
        attendance_taker: "",
        userLists: [],
        updateUser: {
            attendance_id: "",
            student_id: "",
            attendance_status: "",
        },
        date: new Date().toDateString(),
    });

    const [teacherAttendanceDetails, setTeacherAttendanceDetails] = useState({
        id: {
            degree_id: "",
            department_id: "",
        },
        name: {
            degree_id: "",
            department_id: "",
        },
        userLists: [],
        updateUser: {
            attendance_id: "",
            teacher_id: "",
            attendance_status: "",
        },
        date: new Date().toDateString(),
    });

    const [teacherCourses, setTeacherCourses] = useState({
        id: {
            teacher_id: "",
            degree_id: "",
            department_id: "",
        },
        name: {
            teacher_id: "",
            degree_id: "",
            department_id: "",
        },
        courses: [],
    });

    const [markDetails, setMarkDetails] = useState({
        id: {
            degree_id: "",
            department_id: "",
            course_id: "",
            teacher_id: "",
            exam_type: "",
            year: "",
        },
        name: {
            degree_id: "",
            department_id: "",
            course_id: "",
            teacher_id: "",
            exam_type: "",
            monthly_test_no: "",
            year: "",
            studentClass: "",
        },
        total_mark: "",
        total_practical_mark: "",
        total_viba_mark: "",
        total_cq_mark: "",
        total_mcq_mark: "",
        total_lab_mark: "",
        userLists: [],
        updateUser: {
            mark_id: "",
            student_id: "",
            student_name: "",
            student_roll: "",
            student_mark: "",
            cq_mark: "",
            mcq_mark: "",
            practical_mark: "",
            viba_mark: "",
        },

        date: new Date().toDateString(),
    });

    const [receiptDetails, setReceiptDetails] = useState({
        id: {
            degree_id: "",
            department_id: "",
            cls: "",
            feeType_id: "",
        },
        name: {
            degree_id: "",
            department_id: "",
            cls: "",
            feeType_id: "",
        },
        userDetails: {
            receipt_id: "",
            feeType_id: "",
            student_id: "",
            student_roll: "",
            student_name: "",
            student_father: "Karim Molla",
            student_session: "",
            payment_month: "",
            payment_status: "Unpaid",
            receipt_by: "",
            id: "",
            receipt__date: "",
            disable: false,
            total_fee: 0,
            current_month_fee: 0,
            debt: 0,
            advance_fee: 0,
            admission_fee: 0,
            re_admission_fee: 0,
            college_change_fee: 0,
            health_check_fee: 0,
            admission_form_fee: 0,
            admission_center_fee: 0,
            exam_center_fee: 0,
            practical_fee: 0,
            certificate_fee: 0,
            affiliate_fee: 0,
            identity_card_fee: 0,
            registration_fee: 0,
            library_fee: 0,
            library_safety_fee: 0,
            lab_safety_fee: 0,
            land_lease_fee: 0,
            session_fee: 0,
            internal_exam_fee: 0,
            subject_change_fee: 0,
            common_room_fee: 0,
            poor_fund_fee: 0,
            magazine_fee: 0,
            rover_scout_fee: 0,
            bncc_fee: 0,
            red_crescent_fee: 0,
            mosque_fee: 0,
            transmission_fee: 0,
            development_fee: 0,
            rotating_staff_fee: 0,
            transport_fee: 0,
            student_parliament_fee: 0,
            social_development_fee: 0,
            cultural_fee: 0,
            sports_fee: 0,
            outdoor_games_fee: 0,
            study_tour_fee: 0,
            management_fee: 0,
            ict_fee: 0,
            science_club_fee: 0,
            cycle_garage_fee: 0,
            misc_fee: 0,
            website_fee: 0,
        },
        userLists: [],
        date: new Date().toDateString(),
    });

    // Admin api state
    const [lists, setLists] = useState({
        degree: [],
        department: {
            hsc: [],
            degree: [],
        },
        courses: {},
        labs: {},
        array: { department: [] },
    });

    const [studentDetails, setStudentDetails] = useState([]);

    const [studentDetailsForAddCourse, setStudentDetailsForAddCourse] =
        useState(false);

    const [updateStudentDetails, setUpdateStudentDetaiils] = useState();

    const [sendingCourse, setSendingCourse] = useState({});

    const signOut = () => {
        Cookies.remove("user");
        window.location.href = "/";
    };

    // Api Calls

    useEffect(() => {
        const localLang = JSON.parse(localStorage.getItem("lang"));
        localLang !== null && setLang(localLang);

        lang === "EN"
            ? (document.body.style.fontFamily = `"Roboto", sans-serif`)
            : (document.body.style.fontFamily = `"Ekushey Lohit"`);
    }, [lang, lists]);

    useEffect(() => {
        const tokenGenerator = Cookies.get("user");

        if (!tokenGenerator) {
            return;
        }

        const { api, email, accessToken } = JSON.parse(tokenGenerator);

        user.accessToken === "" &&
            user.email === "" &&
            user.name === "" &&
            user.api === "" &&
            api &&
            email &&
            accessToken &&
            getUser(setUser, setLoading, api, email, accessToken);
    }, [user.accessToken, user.email, user.api, user.name]);

    return (
        <DataContext.Provider
            value={{
                lang,
                setLang,
                attendanceDetails,
                setAttendanceDetails,
                teacherAttendanceDetails,
                setTeacherAttendanceDetails,
                teacherCourses,
                setTeacherCourses,
                markDetails,
                setMarkDetails,
                receiptDetails,
                setReceiptDetails,
                user,
                signOut,
                lists,
                setLists,
                loading,
                setLoading,
                sendingCourse,
                setSendingCourse,
                studentDetailsForAddCourse,
                setStudentDetailsForAddCourse,
                updateStudentDetails,
                setUpdateStudentDetaiils,
                studentDetails,
                setStudentDetails,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};

// (document.body.style.fontFamily = `"Roboto", sans-serif`)
// (document.body.style.fontFamily = `"Poppins", sans-serif`)
