import React, { useState } from "react";
import Marquee from "./elements/Marquee";
import NavbarContent from "./elements/NavbarContent";
import "../../css/navbar/navbar.scss";

const Navbar = ({ lang, setLang }) => {
    const [menu, setMenu] = useState("");

    const handleBangla = () => {
        localStorage.setItem("lang", JSON.stringify("বাং"));
        setLang("বাং");
    };

    const handleEnglish = () => {
        localStorage.setItem("lang", JSON.stringify("EN"));
        setLang("EN");
    };

    const menuClick = (e) => {
        const target = e.target.parentElement.firstChild.innerText;
        const nextElement = e.target.parentElement.nextSibling;
        document.getElementById("menu").innerText = target;
        nextElement.classList.add("active");
        document.querySelector(".back__btn").style.display = "block";
    };

    const backClick = () => {
        document
            .querySelectorAll(".sub__dropdown")
            .forEach((sub) => sub.classList.remove("active"));
        document.getElementById("menu").innerText = "Menu";
        document.querySelector(".back__btn").style.display = "none";
    };

    const openMenu = () => {
        setMenu("active");
        document.body.classList.add("res__layer");
    };

    const closeMenu = () => {
        setMenu("");
        document.body.classList.remove("res__layer");
    };

    return (
        <div id="navbar">
            <Marquee />

            <NavbarContent
                lang={lang}
                menu={menu}
                closeMenu={closeMenu}
                openMenu={openMenu}
                menuClick={menuClick}
                backClick={backClick}
                handleBangla={handleBangla}
                handleEnglish={handleEnglish}
            />
        </div>
    );
};

export default Navbar;
