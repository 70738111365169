import React from "react";
import Link from "./link/Link";

const Forms = ({ lang, closeMenu }) => {
    return (
        <li>
            <Link
                closeMenu={closeMenu}
                to="/form-fill-up"
                child={lang === "EN" ? "Forms" : "ফর্ম"}
            />
        </li>
    );
};

export default Forms;
