import React from "react";
import Link from "./link/Link";

const AboutUsLink = ({ closeMenu, lang }) => {
    return (
        <li>
            <Link
                closeMenu={closeMenu}
                to="/about-us"
                child={lang === "EN" ? "about us" : "আমাদের সম্পর্কে"}
            />
        </li>
    );
};

export default AboutUsLink;
