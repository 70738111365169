import React from "react";
import { NavLink } from "react-router-dom";

const Link = ({ closeMenu, to, child }) => {
    return (
        <NavLink
            reloadDocument
            className={({ isActive }) => (isActive ? "active" : "")}
            onClick={closeMenu}
            to={to}
        >
            {child}
        </NavLink>
    );
};

export default Link;
