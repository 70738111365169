import React from "react";
import Link from "./link/Link";

const BangabandhuCornerLink = ({ closeMenu, lang }) => {
    return (
        <li>
            <Link
                closeMenu={closeMenu}
                to="/bangabandhu-corner"
                child={
                    lang === "EN" ? "Bangabandhu Corner" : "বঙ্গবন্ধু কর্নার"
                }
            />
        </li>
    );
};

export default BangabandhuCornerLink;
