import React, { Suspense, lazy, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import "./App.css";
import NotFound from "./pages/NotFound";
import Preloader from "./components/PreLoader";
import AdminRoute from "./routes/admin/AdminRoute";
import TeacherRoute from "./routes/teacher/TeacherRoute";
import StudentRoute from "./routes/student/StudentRoute";
import Cookies from "js-cookie";

const LazyHome = lazy(() => import("./pages/Home"));
const LazyDegree = lazy(() => import("./pages/Degree"));
const LazyHsc = lazy(() => import("./pages/Hsc"));
const LazyScience = lazy(() => import("./pages/courses/Science"));
const LazyCommerce = lazy(() => import("./pages/courses/Commerce"));
const LazyArts = lazy(() => import("./pages/courses/Arts"));
const LazyBa = lazy(() => import("./pages/courses/Ba"));
const LazyBsc = lazy(() => import("./pages/courses/Bsc"));
const LazyBss = lazy(() => import("./pages/courses/Bss"));
const LazyBbs = lazy(() => import("./pages/courses/Bbs"));
const LazyVisitUs = lazy(() => import("./pages/VisitUs"));
const LazyAbout = lazy(() => import("./pages/About"));
const LazyBangabandhuCorner = lazy(() => import("./pages/BangabandhuCorner"));
const LazyHscTeachers = lazy(() => import("./pages/teachers/HscTeachers"));
const LazyDegreeTeachers = lazy(() =>
    import("./pages/teachers/DegreeTeachers")
);
const LazySignUp = lazy(() => import("./pages/SignUp"));
const LazySignIn = lazy(() => import("./pages/SignIn"));
const LazyFormFillUp = lazy(() => import("./pages/FormFillUp"));

const App = () => {
    const cookieValue = Cookies.get("user");
    const initialValue =
        typeof cookieValue !== "undefined" && cookieValue
            ? JSON.parse(cookieValue).accessToken
            : false;

    const [authenticated, setauthenticated] = useState(initialValue);

    return (
        // <DataProvider>
        <Layout>
            <Suspense fallback={<Preloader />}>
                <Routes>
                    <Route path="/" element={<LazyHome />} />
                    <Route path="/degree" element={<LazyDegree />} />
                    <Route path="/hsc" element={<LazyHsc />} />
                    <Route path="/science-course" element={<LazyScience />} />
                    <Route path="/commerce-course" element={<LazyCommerce />} />
                    <Route path="/arts-course" element={<LazyArts />} />
                    <Route path="/ba-course" element={<LazyBa />} />
                    <Route path="/bsc-course" element={<LazyBsc />} />
                    <Route path="/bss-course" element={<LazyBss />} />
                    <Route path="/bbs-course" element={<LazyBbs />} />
                    <Route path="/visit-us" element={<LazyVisitUs />} />
                    <Route path="/about-us" element={<LazyAbout />} />
                    <Route
                        path="/bangabandhu-corner"
                        element={<LazyBangabandhuCorner />}
                    />

                    <Route
                        path="/sign-up"
                        element={
                            !authenticated ? (
                                <LazySignUp
                                    setauthenticated={setauthenticated}
                                />
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />
                    <Route
                        path="/sign-in"
                        element={
                            !authenticated ? (
                                <LazySignIn
                                    setauthenticated={setauthenticated}
                                />
                            ) : (
                                <Navigate to="/" replace />
                            )
                        }
                    />

                    <Route path="/form-fill-up" element={<LazyFormFillUp />} />

                    <Route path="/hsc-teachers" element={<LazyHscTeachers />} />
                    <Route
                        path="/degree-teachers"
                        element={<LazyDegreeTeachers />}
                    />

                    {/* ============= Admin Dashboard ============== */}

                    <Route
                        path="/admin-dashboard/*"
                        element={
                            !authenticated && <Navigate to="/sign-in" replace />
                        }
                    />

                    {/* ============= Teacher Dashboard ============== */}

                    <Route
                        path="/teacher-dashboard/*"
                        element={
                            !authenticated && <Navigate to="/sign-in" replace />
                        }
                    />

                    {/* ============= Student Dashboard ============= */}

                    <Route
                        path="/student-dashboard/*"
                        element={
                            !authenticated && <Navigate to="/sign-in" replace />
                        }
                    />

                    <Route path="/*" element={<NotFound />} />
                </Routes>

                {authenticated && <AdminRoute />}
                {authenticated && <TeacherRoute />}
                {authenticated && <StudentRoute />}
            </Suspense>
        </Layout>
        // </DataProvider>
    );
};

export default App;
