import React, { useState } from "react";
import { eventUpload } from "../../../../../../../api/admin/admin";
import { useAuth } from "../../../../../../../context/Context";
import SelectDate from "../../../../../common/SelectDate";

const AddEvent = ({ handleClose }) => {
    const { user } = useAuth();
    const [obj, setObj] = useState({
        title: "",
        date: "N/A",
    });
    const [show, setShow] = useState(false);

    const [error, setError] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [processingMsg, setProcessingMsg] = useState("");

    const handleChange = (e) => {
        setObj((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        eventUpload(
            user.accessToken,
            obj.title,
            new Date().toDateString(),
            obj.date,
            setSuccessMsg,
            setError,
            setProcessingMsg
        );
    };

    const handleDate = () => {
        show ? setShow(false) : setShow(true);
    };

    return (
        <div className="form__wrapper">
            <i onClick={handleClose} className="fa-solid fa-xmark"></i>
            <div className="heading">Add a New Event</div>

            {error !== "" && (
                <div
                    style={{
                        position: "sticky",
                        right: "0",
                        top: "-3rem",
                        width: "100%",
                        fontSize: "1.6rem",
                        padding: "1rem 2rem",
                        textAlign: "center",
                        zIndex: "10",
                    }}
                    className="alert alert-danger"
                    role="alert"
                >
                    {error}
                </div>
            )}

            {processingMsg !== "" && (
                <div
                    style={{
                        position: "sticky",
                        right: "0",
                        top: "-3rem",
                        width: "100%",
                        fontSize: "1.6rem",
                        padding: "1rem 2rem",
                        textAlign: "center",
                        zIndex: "10",
                    }}
                    className="alert alert-warning"
                    role="alert"
                >
                    {processingMsg}
                    <div
                        style={{
                            marginLeft: "1rem",
                        }}
                        className="spinner-border text-warning"
                        role="status"
                    ></div>
                </div>
            )}

            {successMsg !== "" && (
                <div
                    style={{
                        position: "sticky",
                        right: "0",
                        top: "-3rem",
                        width: "100%",
                        fontSize: "1.6rem",
                        padding: "1rem 2rem",
                        textAlign: "center",
                        zIndex: "10",
                    }}
                    className="alert alert-success"
                    role="alert"
                >
                    {successMsg}
                </div>
            )}

            <form className="form__box" onSubmit={handleSubmit}>
                <textarea
                    value={obj.title}
                    onChange={handleChange}
                    name="title"
                    placeholder="Description:"
                ></textarea>

                <SelectDate
                    handleDate={handleDate}
                    show={show}
                    user={obj.date === "N/A" ? "Select Date  +" : obj.date}
                    setUser={setObj}
                    setShow={setShow}
                    title="Expire Date:"
                    cls="normal"
                />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default AddEvent;
