import React from "react";
import UserBar from "../userBar/UserBar";
import { NavLink } from "react-router-dom";

const LanguageBar = ({ handleBangla, handleEnglish, user }) => {
    return (
        <div className="language__bar">
            <span onClick={handleBangla}>বাং</span>
            <span onClick={handleEnglish}>EN</span>
            {user.name !== "" && <UserBar cls="mb__none" />}
        </div>
    );
};

export default LanguageBar;
