import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import avatar from "../../../../image/user/user.png";
import { useAuth } from "../../../../context/Context";

const UserBar = ({ cls }) => {
    const location = useLocation();
    const { user, signOut } = useAuth();
    const buttonRef = useRef(null);

    const [active, setActive] = useState("");
    const [navigate, setNavigate] = useState("");

    const handleClick = (e) => {
        active === "active" ? setActive("") : setActive("active");
    };

    const handleDashboard = () => {};

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                buttonRef.current &&
                !buttonRef.current.contains(event.target) &&
                event.target.id !== "avatar"
            ) {
                setActive("");
            }
        };

        switch (user.api) {
            case "admin":
                setNavigate("/admin-dashboard");
                break;
            case "teacher":
                setNavigate("/teacher-dashboard");
                break;
            case "hsc/student":
                setNavigate("/student-dashboard");
                break;
            case "bsc/student":
                setNavigate("/student-dashboard");
                break;
            default:
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [buttonRef, user]);

    return (
        <div className={`user__bar ${cls}`}>
            <div id="avatar" onClick={handleClick} className="img__div">
                <img
                    style={{ pointerEvents: "none" }}
                    src={user.image ? user.image : avatar}
                    alt="avatar"
                />
            </div>
            <div ref={buttonRef} className={`dropdown__bar ${active}`}>
                <div className="user__name">{user.name}</div>
                <NavLink onClick={handleDashboard} to={navigate}>
                    Go To Dashboard
                </NavLink>

                <NavLink
                    reloadDocument
                    onClick={signOut}
                    to={location.pathname}
                >
                    SignOut
                </NavLink>
            </div>
        </div>
    );
};

export default UserBar;
