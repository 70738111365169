import React from "react";
import notFound from "../image/notFound/notFound.png";
import "../css/notFound/notFound.scss";

const NotFound = () => {
    return (
        <div className="not__found">
            <img src={notFound} alt="Not Found" />
        </div>
    );
};

export default NotFound;
