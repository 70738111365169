import React from "react";

const BottomFooterContent = ({ lang }) => {
    return (
        <div className="bottom">
            <div className="left">
                <span>{lang === "EN" ? " Address : " : "ঠিকানা : "}</span>

                <span>
                    {lang === "EN"
                        ? "Parbatipur, Dinajpur"
                        : "পার্বতীপুর,দিনাজপুর"}
                </span>
            </div>
            <div className="mid">
                <span>{lang === "EN" ? "Developed by : " : "নির্মাণে : "}</span>
                <a href="https://syncchrontech.com/">
                    Sync Chron Tech Pvt. Ltd.
                </a>
            </div>
            <div className="right">© 2022 College, All Rights Reserved</div>
        </div>
    );
};

export default BottomFooterContent;
