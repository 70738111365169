import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "../../pages/NotFound";
const LazyStudentHome = lazy(() =>
    import("../../pages/dashboard/student/Home")
);
const LazyStudentAttendance = lazy(() =>
    import("../../pages/dashboard/student/Attendance")
);
const LazyStudentResult = lazy(() =>
    import("../../pages/dashboard/student/Result")
);
const LazyStudentFee = lazy(() => import("../../pages/dashboard/student/Fee"));

const StudentRoute = () => {
    return (
        <Routes>
            <Route path="/student-dashboard" element={<LazyStudentHome />} />

            <Route
                path="/student-dashboard/student-attendance"
                element={<LazyStudentAttendance />}
            />
            <Route
                path="/student-dashboard/results"
                element={<LazyStudentResult />}
            />
            <Route
                path="/student-dashboard/fees"
                element={<LazyStudentFee />}
            />
            <Route path="/student-dashboard/*" element={<NotFound />} />
        </Routes>
    );
};

export default StudentRoute;
